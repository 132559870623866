import React, { Component, Fragment, type ReactElement } from 'react'
import HeaderWrapper from '../Header/HeaderWrapper/HeaderWrapper'
import BackToTop from '../BackToTop/BackToTop'
import './style.scss'

interface Props {
  shouldHideBackToTop?: boolean
  subtitle: string
  subtitle2: string
  title: string
}

class Title extends Component<Props> {
  render(): ReactElement {
    const { subtitle, subtitle2, title } = this.props

    return (
      <Fragment>
        <div className='title'>
          <HeaderWrapper shouldShowBlackLogo={false} />
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {this.props.shouldHideBackToTop ? null : <BackToTop />}
          <div className='title-content'>
            <h1>{title.toUpperCase()}</h1>
            <p>
              <div>{subtitle}</div>
              <div>{subtitle2}</div>
            </p>
          </div>
        </div>
        {title !== 'news' && <div className='title-blackBorder'></div>}
      </Fragment>
    )
  }
}

export default Title
