import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import images from '../../common/images'
import Title from '../../common/Title/Title'
import NavigationButton from '../../common/NavigationButton/NavigationButton'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import './style.css'

class PageNotFound extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <div className='pageNotFound short-page-adjustment main-content'>
          <Title
            shouldHideBackToTop={true}
            title='404 error'
            subtitle='ページが存在しません'
            subtitle2=''
          />
          <div className='pageNotFound-content content'>
            <img
              alt='page-not-found-question-mark'
              className='pageNotFound-questionMark'
              src={images.questionMark}
            />
            <h2 id='h2-default'>URLをご確認のうえ、再度アクセスしてください</h2>
            <NavigationButton height='99px' width='285px' label='トップページへ戻る' path='' />
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }
}

export default PageNotFound
