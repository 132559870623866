import type ValueDto from './ValueDto'

export const values: ValueDto[] = [
  {
    index: '01',
    title: '卓越しろ',
    englishTitle: 'Strive for Excellence',
    content: `一人ひとりが卓越した能力を持ち、日々の学びを怠らない姿勢は、個人のみならずチームに良い影響を及ぼします。
    個人の力を結集した、卓越したチームによって、私たちは「期待の一歩先」「常識の一歩先」を追求し続けます。`
  },
  {
    index: '02',
    title: '迅速であれ',
    englishTitle: 'Be Expeditious',
    content: `スピードは価値です。どこよりも早く始め、どこよりも早く行動し、どこよりも早く改善することで、より良い結果が生まれます。
    私たちは全業務を徹底的に能率化し、あらゆる局面において常に最速を目指します。`
  },
  {
    index: '03',
    title: '飛躍する',
    englishTitle: 'Exponential Growth',
    content: `飛躍的な成長は一朝一夕にして起こりません。
    大きな目標から逆算した「やるべきこと」を明確にし、一人ひとりが目の前のミッションを着実にクリアすることこそが、指数関数的な成長に繋がります。`
  },
  {
    index: '04',
    title: '歴史を築く',
    englishTitle: 'Build a Legacy',
    content:
      '私たちはこれまでにない革新的な存在であり続けます。そのために、前例にとらわれず挑戦します。大胆に、リスクを恐れず創造した価値は、やがて歴史となります。'
  }
]
