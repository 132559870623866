import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import Title from '../../common/Title/Title'
import images from '../../common/images'
import NewsRow from './NewsRow/NewsRow'
import NewsMenu from './NewsMenu/NewsMenu'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import type INewsPresenter from './Presenter/INewsPresenter'
import './style.css'
import type NewsDto from './Presenter/NewsDto'

interface Props {
  presenter: INewsPresenter
}

class News extends Component<Props> {
  componentDidMount(): void {
    this.props.presenter.setView(this)
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜ニュース'

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.has('year')) {
      const selectedYear = parseInt(urlParams.get('year') ?? '', 10)
      this.props.presenter.filterByYear(selectedYear)
    }
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { presenter } = this.props

    if (presenter.isLoading()) {
      return <LoadingState />
    }

    return (
      <Fragment>
        <div className='news main-content'>
          <LoadingState />
          <div>
            <div className='news-title'>
              <Title
                title='news'
                subtitle='イベント、プレスリリース等の'
                subtitle2='最新の情報をご案内しています'
              />
              <NewsMenu presenter={presenter} shouldRedirect={false} />
            </div>
            <div className='short-content-adjustment'>
              {presenter.getNews().map((newsDto) => {
                return (
                  <Fragment key={newsDto.year}>
                    <div className='news-content content-padding container'>
                      <h2>{newsDto.year}</h2>
                      <div className='news-list'>{this.renderNewsList(newsDto.news)}</div>
                    </div>
                  </Fragment>
                )
              })}
            </div>
            <div className='news-paginator'>
              {presenter.getPaginatorLength().length > 1 && (
                <img
                  alt='paginator-right'
                  src={images.paginatorRight}
                  onClick={() => this.props.presenter.goToPreviousPage()}
                />
              )}
              {presenter.getPaginatorLength().map((item) => {
                return (
                  <span
                    className={
                      presenter.getCurrentPageIndex() === item
                        ? 'news-selectedPage'
                        : 'news-no-select'
                    }
                    key={item}
                    onClick={() => this.props.presenter.updatePage(item)}
                  >
                    {item}
                  </span>
                )
              })}
              {presenter.getPaginatorLength().length > 1 && (
                <img
                  alt='paginator-left'
                  src={images.paginatorLeft}
                  onClick={() => this.props.presenter.goToNextPage()}
                />
              )}
            </div>
            <Footer />
          </div>
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }

  public update = (): void => {
    this.setState({})
  }

  private readonly updatePage = (index: number): void => {
    this.props.presenter.updatePage(index)
  }

  private readonly renderNewsList = (news: NewsDto[]): ReactElement[] => {
    return news.map((n) => {
      return (
        <NewsRow
          category={n.category}
          id={n.id}
          image={n.cover}
          date={n.date}
          key={n.id}
          tag={n.category.name}
          link={n.link}
          preview={n.title}
          type={n.type}
        />
      )
    })
  }
}

export default News
