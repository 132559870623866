import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import Title from '../../common/Title/Title'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import images from '../../common/images'
import './style.scss'
import MediakitIva from './MediakitIva'
import MediakitFb from './MediakitFb'

class Mediakit extends Component {
  componentDidMount(): void {
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜メディアキット'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
  }

  render(): ReactElement {
    return (
      <Fragment>
        <div className='mediakit main-content'>
          <LoadingState />
          <Title title='MEDIA KIT' subtitle='メディアキット' subtitle2='' />
          <div className='mediakit-content-area section-animation'>
            <div className='mediakit-content'>
              <p>
                IVAおよびFAKEBUSTERSのロゴをダウンロードいただけます。ご使用の際は各ロゴのブランドガイドラインをご覧いただき、ガイドライ
              </p>
              <p>ンにはない使用方法に関しては「お問い合わせ」よりお問い合わせください。</p>
            </div>
            <div className='mediakit-content-link'>
              <span onClick={() => this.handleAnchorLink('iva-logos')}>
                IVA Logos
                <img alt='arrow-link' src={images.greyArrowRight} width={40} height={40} />
              </span>
              <span onClick={() => this.handleAnchorLink('fakebustrs-logos')}>
                FAKEBUSTERS Logos
                <img alt='arrow-link' src={images.greyArrowRight} width={40} height={40} />
              </span>
            </div>
          </div>
          <div className='mediakit-logos-area container'>
            <MediakitIva />
            <MediakitFb />
          </div>
          <div>
            <Footer />
          </div>
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }

  private readonly handleAnchorLink = (targerId: string): void => {
    const target = document.getElementById(targerId)?.offsetTop ?? 0
    if (target) {
      window.scrollTo({
        top: target - 60,
        left: 0
      })
    }
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = document.querySelectorAll('.title-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }
}

export default Mediakit
