import React, { Component, type ReactElement } from 'react'
import './style.css'

interface Props {
  content: string
  icon: string
  isSelected: boolean
  onClick: any
  title: string
  titleSecondLine: string
}

class ContactCategory extends Component<Props> {
  render(): ReactElement {
    const { content, icon, isSelected, onClick, title, titleSecondLine } = this.props

    return (
      <div className={`contactCategory contactCategory${isSelected ? '-selected' : ''}`} onClick={onClick}>
        <div className='contactCategory-icon'>
          <img alt='contact-category-icon' src={icon} />
        </div>
        <div>
          <p className='contactCategory-title'>{title}</p>
          <p className='contactCategory-title'>{titleSecondLine}</p>
          <p className='contactCategory-content'>{content}</p>
          {isSelected && <span className='contactCategory-selectedMark'>選択中</span>}
        </div>
      </div>
    )
  }
}

export default ContactCategory
