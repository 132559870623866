import React, { Component, type ReactElement } from 'react'
import images from '../images'
import './style.css'

interface Props {
  label: string
  link: string
  shouldOpenNewTab: boolean
}

class NavigationLink extends Component<Props> {
  render(): ReactElement {
    const { label, link, shouldOpenNewTab } = this.props

    return (
      <a
        className='navLink'
        href={link}
        referrerPolicy='no-referrer'
        target={shouldOpenNewTab ? '_blank' : '_self'}
        rel='noreferrer'
      >
        <p>
          <img alt='arrow-right' className='services-fbLinkArrow' src={images.greyArrowRight} />{' '}
          {label}
        </p>
      </a>
    )
  }
}

export default NavigationLink
