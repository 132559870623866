import React, { Component, type ReactElement } from 'react'
import type INewsPresenter from '../Presenter/INewsPresenter'
import './style.css'

interface Props {
  presenter: INewsPresenter | null
  shouldRedirect: boolean
}

class NewsMenu extends Component<Props> {
  componentDidMount(): void {
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
  }

  render(): ReactElement {
    return (
      <div className='newsMenuWrapper'>
        <div className='newsMenu' onClick={this.handleClick}>
          <div className='newsMenu-bars'>
            <div className='bar1'></div>
            <div className='bar2'></div>
            <div className='bar3'></div>
          </div>
          <span>過去のニュース</span>
        </div>
        <div className='newsMenu-menu'>
          <span onClick={() => this.selectFilter('')}>ALL</span>
          <span onClick={() => this.selectFilter('2024')}>2024</span>
          <span onClick={() => this.selectFilter('2023')}>2023</span>
          <span onClick={() => this.selectFilter('2022')}>2022</span>
          <span onClick={() => this.selectFilter('2021')}>2021</span>
          <span onClick={() => this.selectFilter('2020')}>2020</span>
          <span onClick={() => this.selectFilter('2019')}>2019</span>
        </div>
      </div >
    )
  }

  private readonly selectFilter = (year: string): void => {
    if (this.props.shouldRedirect) {
      window.location.href = `${window.location.href.includes('localhost') ? 'http://localhost:3000/' : 'http://dev.iva-corporation.com/'}news?year=${year}`
    }
    this.props.presenter?.filterByYear(Number(year))
  }

  private readonly handleScroll = (): void => {
    const newsMenuElement = document.querySelector('.newsMenu') as HTMLElement
    const headerHeight = 70

    if (newsMenuElement) {
      if (window.pageYOffset > headerHeight) {
        // newsMenuElement.style.display = 'none'
      } else {
        newsMenuElement.style.display = 'flex'
      }
    }
  }

  private readonly handleClick = (): void => {
    this.toggleNewsMenuIcon()
    this.toggleShowNewsMenu()
  }

  private readonly toggleNewsMenuIcon = (): void => {
    const icon = document.querySelector('.newsMenu') as HTMLElement

    if (icon) {
      icon.classList.toggle('change')
    }
  }

  private readonly toggleShowNewsMenu = (): void => {
    const icon = document.querySelector('.newsMenu') as HTMLElement
    const options = Array.from(document.querySelectorAll('.newsMenu-menu > span')) as HTMLElement[]

    if (Array.from(icon.classList).includes('change')) {
      options.forEach((option) => {
        option.style.height = '20px'
        option.style.visibility = 'visible'
        option.style.cursor = 'pointer'
      })
    } else {
      options.forEach((option) => {
        option.style.visibility = 'hidden'
        option.style.height = '0'
      })
    }
  }
}

export default NewsMenu
