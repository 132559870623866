import React, { Component, Fragment, type ReactElement } from 'react'
import Title from '../../common/Title/Title'
import Footer from '../../common/Footer/Footer'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import '../../common/content.css'

class TermsAndConditions extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <div className='termsAndConditions short-page-adjustment main-content'>
          <Title title='terms & condition of use' subtitle='サイト利用規約' subtitle2='' />
          <div className='content container'>
            <p className='mid-para'>
              このサイトは、IVA株式会社（以下「IVA」といいます）が管理、運営しています。このサイトを利用される場合は、ここに記載した条件に準拠いただくものとします。このサイトをご利用いただいた場合には、以下のすべてにご同意いただいたものとさせていただきます。また、IVAの関連会社のサイトには、このサイトの利用条件とは違う利用条件を有している場合がありますが、その場合には、当該サイトの利用条件に準拠いただくものとします。
            </p>
            <div className='termsAndConditions-listItems'>
              <li>総則</li>
              <li>禁止事項</li>
              <li>商標・著作権等</li>
              <li>免責事項</li>
              <li>リンクについて</li>
              <li>準拠法および管轄裁判</li>
              <li>推奨環境</li>
              <li>RSSについて</li>
              <li>セキュリティについて</li>
              <li>アクセスログについて</li>
              <li>ソーシャルメディアについて</li>
              <li>アクセシビリティについて</li>
            </div>
            <h3>1．総則</h3>
            <p className='mid-para'>
              このサイトは、あくまでも皆さまの便宜に供するためのものであり、非営利目的に限り、皆さまご自身の責任でご利用いただくことができるものとします。
            </p>
            <h3>2．禁止事項</h3>
            <p className='mid-para'>
              第三者もしくはIVAに損害もしくは不利益を与える行為、または第三者もしくはIVAの信用や名誉を損なう行為は、その虞のある行為も含めて禁止させていただきます。
            </p>
            <h3>3．商標・著作権等</h3>
            <p className='mid-para'>
              このサイト及び情報は、IVAが保有または管理する著作権、商標権、その他の知的財産権により保護されています。このサイトをご利用いただくにあたっては、いかなる著作権、商標権その他の知的財産権に関する法令をも遵守いただくものとします。個人の私的使用、その他著作権法により認められている場合を除き、このサイトまたはコンテンツを、著作権者に無断で、複製、送信、頒布、改変、翻訳等利用することは著作権法違反となります。悪意を含む悪質な内容であるとIVAが判断した場合には、厳格に対処いたします。
            </p>
            <h3>4．免責事項</h3>
            <li>
              IVAは、このサイトにコンテンツを掲載するにあたって、その内容、機能等について細心の注意を払っておりますが、コンテンツの内容が正確であるかどうか、最新のものであるかどうか、安全なものであるか等につきましては保証をするものではなく、何らの責任を負うものではありません。また、IVAは、通知することなく、このサイトまたはサイトに掲載した情報の訂正、修正、追加、中断、削除等をいつでも行うことができるものとします。
            </li>
            <li>
              このサイト、またはコンテンツのご利用により、万一、ご利用者に何らかの不都合や損害が発生したとしても、IVAは何らの責任を負うものではありません。
            </li>
            <h3>5．リンクについて</h3>
            <li>このサイトにリンクを張ることを希望される場合は、事前にIVAの承諾を取得願います。</li>
            <li>
              このサイトから第三者のサイトにリンクを張っている場合や、第三者のサイトからこのサイトにリンクを張っている場合があります。いずれの場合も、第三者のサイトの内容は、第三者の責任で管理・運営されるものであり、それらをご利用になったことによって生ずるいかなる不都合や損害についても、IVAは責任を負いかねます。
            </li>
            <h3>6．準拠法および管轄裁判所</h3>
            <p className='mid-para'>
              このサイト及び情報は、IVAが保有または管理する著作権、商標権、その他の知的財産権により保護されています。このサイトをご利用いただくにあたっては、いかなる著作権、商標権その他の知的財産権に関する法令をも遵守いただくものとします。個人の私的使用、その他著作権法により認められている場合を除き、このサイトまたはコンテンツを、著作権者に無断で、複製、送信、頒布、改変、翻訳等利用することは著作権法違反となります。悪意を含む悪質な内容であるとIVAが判断した場合には、厳格に対処いたします。
            </p>
            <h3>7．推奨環境</h3>
            <p className='mid-para'>パソコン Microsoft Internet Explorer 11</p>
            <p className='mid-para'>Google Chrome 最新版</p>
            <p className='mid-para'>Mozilla Firefox 最新版</p>
            <p className='mid-para'>Safari 最新版</p>
            <p className='mid-para'>Android Android OS最新版標準搭載ブラウザ</p>
            <p className='mid-para'>iPhone iOS最新版標準搭載ブラウザ</p>
            <p className='mid-para'>iPad iOS最新版標準搭載ブラウザ</p>
            <li>
              各社提供のブラウザは、バージョンアップによって新しい機能の追加やセキュリティの強化を行っていますので、お使いのブラウザのバージョンを常に最新版にアップデートしてご利用ください。
            </li>
            <p className='mid-para'>
              当WEBサイトでは、より便利で快適にご利用いただくためにJavaScriptを使用しています。
            </p>
            <p className='mid-para'>
              ご使用のブラウザ設定においてJavaScriptをオン（有効）にされていない場合には、正しく機能しない、もしくは正しく表示されない場合がございます。
            </p>
            <h3>8．RSSについて</h3>
            <p className='mid-para'>
              このサイトではRSSにより最新の更新情報（プレスリリースやお知らせなど）を提供しています。
            </p>
            <p className='mid-para'>
              RSSリーダーや対応ブラウザなどをご利用いただくことで、更新情報を自動でお知らせします。
            </p>
            <h3>9．セキュリティについて</h3>
            <p className='mid-para'>
              当社ホームページは、プライバシーの保護や、セキュリティ対策に「SSL」を用いた暗号化を施し、通信の秘匿性を高めております。
            </p>
            <h3>10．アクセスログについて</h3>
            <p className='mid-para'>
              このサイトでは利用者各位の使用するIPアドレスを含むアクセスログの記録と保管を行っています。アクセスログは、アクセスの傾向や数といった統計分析以外の用途には使用していません。
            </p>
            <h3>11．ソーシャルメディアについて</h3>
            <p className='mid-para'>
              IVAはより多くの皆さまに弊社を知っていただくためにソーシャルメディアを活用し、情報の発信に努めていきます。ご利用にあたって、以下の「ソーシャルメディア利用規約」のリンクをご覧ください。
            </p>
            <li>ソーシャルメディア利用規約</li>
            <h3>12．アクセシビリティについて</h3>
            <p className='mid-para'>
              IVA株式会社では、当ウェブサイトでご提供する情報やサービスについて、年齢・身体的制約・ご利用環境などに関わらず、より多くの皆様に問題無くご利用いただけるよう、アクセシビリティの確保および向上に努めてまいります。
            </p>
            <p className='mid-para content-date'>平成31年4月20日 策定</p>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }
}

export default TermsAndConditions
