import React, { Component, Fragment, type ReactElement } from 'react'
import Title from '../../common/Title/Title'
import Footer from '../../common/Footer/Footer'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import '../../common/content.css'

class SocialMedia extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <div className='socialMedia short-page-adjustment main-content'>
          <Title
            title='SOCIAL MEDIA CONDITIONS'
            subtitle='ソーシャルメディア利用規約'
            subtitle2=''
          />
          <div className='content container'>
            <p className='mid-para'>
              FAKEBUSTERSはより多くの皆さまに当サイトを知っていただくためにソーシャルメディアを活用し、情報の発信に努めていきます。ご利用の際には以下の内容にご同意いただけますよう、お願い致します。
            </p>
            <div>
              <h3>ソーシャルメディアの定義</h3>
              <div>
                <p className='mid-para'>
                  ここではソーシャルメディアをInstagram、Twitter、Facebook、電子掲示板等、インターネットを利用して情報を発信する伝達手段と定義しています。
                </p>
              </div>
            </div>
            <h3>ソーシャルメディアユーザーの皆様へ</h3>
            <ul>
              <li>
                FAKEBUSTERS
                公式アカウント」以外のソーシャルメディア内での従業者による発言は、必ずしも当社の見解を表明しているものではありません。
              </li>
              <li>当社は、ユーザーにより投稿されたコメントについて一切の責任を負いません。</li>
              <li>
                ソーシャルメディアを利用されたこと、もしくは何らかの原因により利用することができなかったことによって生じるお客様のいかなる損害についても、当社は何ら責任を負うものではありません。
              </li>
              <li>
                当社のソーシャルメディアに関連して生じたユーザー間のトラブルまたはユーザーと第三者との間のトラブルによってユーザーまたは第三者の被った損害について責任を負いません。
              </li>
              <li>
                掲載情報には正確性を期しますが、万が一情報に誤りがあった場合には、速やかに修正を行うものとします。
              </li>
              <li>
                当社のアカウントに対して、以下のような行為はご遠慮ください。ユーザーの行為が以下のいずれかに該当する場合、コメントを削除させていただく場合がございます。
              </li>
              <li>本人の承諾なく個人情報を特定、開示、漏洩するもの</li>
              <li>当社または第三者の名誉、信用を傷つけたり、誹謗中傷するもの</li>
              <li>当社または第三者の著作権、肖像権、知的財産権を侵害するもの</li>
              <li>法令や公序良俗に反するもの</li>
              <li>その他、当社が合理的理由により不適切と判断するもの</li>
            </ul>
            <h4>FAKEBUSTERS 公式アカウント（2023年8月現在）</h4>
            <div className='socialList'>
              <ul>
                <li>
                  FAKEBUSTERS
                  <a
                    href='https://www.instagram.com/fakebusters_official/'
                    referrerPolicy='no-referrer'
                    target='_blank'
                    rel='noreferrer'
                  >
                    公式Instagram
                  </a>
                </li>
                <li>
                  FAKEBUSTERS
                  <a
                    href='https://twitter.com/Fakebusters_jp'
                    referrerPolicy='no-referrer'
                    target='_blank'
                    rel='noreferrer'
                  >
                    公式X（旧Twitter）
                  </a>
                </li>
                <li>
                  FAKEBUSTERS
                  <a
                    href='https://www.facebook.com/fakebusterstw'
                    referrerPolicy='no-referrer'
                    target='_blank'
                    rel='noreferrer'
                  >
                    公式FACEBOOK
                  </a>
                </li>
                <li>
                  FAKEBUSTERS
                  <a
                    href='https://jp.linkedin.com/company/fake-busters'
                    referrerPolicy='no-referrer'
                    target='_blank'
                    rel='noreferrer'
                  >
                    公式LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <p className='mid-para'>
                なお、本ソーシャルメディアポリシー（ガイドライン）は予告なく変更される場合があります。
              </p>
              <p className='mid-para content-date'>平成31年4月20日 策定</p>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }
}

export default SocialMedia
