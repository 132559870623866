import React, { Component, Fragment, type ReactElement } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import Footer from '../../common/Footer/Footer'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import WhiteTitle from '../../common/WhiteTitle/WhiteTitle'
import images from '../../common/images'
import careers from '../../images/careers.mp4'
import { values } from './ValuesCard/Values'
import ValuesCard from './ValuesCard/ValuesCard'
import type ValueDto from './ValuesCard/ValueDto'
import BackToTop from '../../common/BackToTop/BackToTop'
import RecruitingSteps from './RecruitingSteps/RecruitingSteps'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import { animationDelayMilliseconds } from '../../common/config'
import './style.scss'
import './swiper.css'

class Careers extends Component {
  private cultureInterval: NodeJS.Timeout | undefined
  componentDidMount(): void {
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜採用情報'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
    // Set up interval to cycle active class
    this.cultureInterval = setInterval(() => {
      const features = document.querySelectorAll('.careers-culture-feature')
      const images = document.querySelectorAll('.culture-img')
      if (features.length > 0) {
        let activeIndex = -1
        features.forEach((feature, index) => {
          if (feature.classList.contains('actived')) {
            activeIndex = index
            feature.classList.remove('actived')
          }
        })
        const nextIndex = (activeIndex + 1) % features.length
        features[nextIndex].classList.add('actived')

        images.forEach((image, index) => {
          if (index === nextIndex) {
            image.classList.add('active')
          } else {
            image.classList.remove('active')
          }
        })
      }
    }, 5000)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
    // Clear the interval
    if (this.cultureInterval) {
      clearInterval(this.cultureInterval)
    }
  }

  render(): ReactElement {
    return (
      <Fragment>
        <div className='careers main-content'>
          <LoadingState />
          <HeaderWrapper shouldShowBlackLogo={true} />
          <BackToTop />
          <WhiteTitle
            pageTitle={images.buildWithUsTitle}
            title='あなたの才能が'
            title2='未来を創る'
            titleWidth='336px'
            subtitle='IVAは一緒に挑戦する仲間を'
            subtitle2='募集しています'
          />
          <div className='career-videoBox' style={{ position: 'relative' }}>
            <video autoPlay={true} controls={false} muted loop={true} playsInline width='100%'>
              <source src={careers} type='video/mp4' />
              Sorry, your browser does not support videos.
            </video>
            <div className='careers-videoTitle'>
              <div className='careers-videoTable'>
                <span className='careers-videoTableLeft'>
                  <img alt='careers-vision' src={images.careersVision} />
                </span>
                <span className='careers-videoTableRight'>私たちのミッション</span>
              </div>
              <span className='careers-videoDivider'></span>
              <div className='careers-titleWrapper'>
                <div className='careers-text'>
                  <h1>AI xヒトで、</h1>
                  <h1>世界にもっと</h1>
                  <h1>輝きを。</h1>
                </div>
                <div className='careers-text'>
                  <p className='mb-3'>AI-DRIVEN and HUMAN-POWERED</p>
                  <p>for dynamic future.</p>
                </div>
              </div>
            </div>
          </div>
          {this.renderMissionSection()}
          {this.renderValuesSection()}
          {this.renderEnvSection()}
          {this.renderCultureSection()}
          {this.renderCommunicationSection()}
          {this.renderRecruitingSection()}
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(true)} />
      </Fragment>
    )
  }

  private readonly renderMissionSection = (): ReactElement => {
    return (
      <div className='careers-mission container'>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>VISION</span>
            <span className='subtitle-with-bg'>私たちのビジョン</span>
          </span>
        </div>
        <div className='section-content align-items-start'>
          <div className=''>
            <h1>
              55兆円市場をハックし、
              <br />
              経済のポテンシャルを解放する
            </h1>
          </div>
          <div>
            <p className='mb-0'>
              モノの価値が毀損されず、適切に評価される世界を実現する。そのために私たちは、世界の価値基準に「新たな常識」をつくります。現在、世界中のモノは偽造品の脅威に晒されることで、本来の価値や魅力が埋もれたままになっています。
            </p>
            <p className='mb-0'>
              私たちは業界の先駆者として、この巨大な偽造品の脅威に立ち向かっていきます。この脅威を解消することで、モノの価値を最大化し、経済のポテンシャルを解放。そんな未来を実現するために、私たちは挑み続けます。
            </p>
          </div>
        </div>

        <div className='careers-contactUs'>
          <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
            <button
              className='nav-button button'
              style={{ height: '70px', width: '285px' }}
              type='button'
            >
              <div className='nav-buttonText'>
                職種を見る
                <div className='arrow right'></div>
              </div>
            </button>
          </a>
          <a
            href='https://www.wantedly.com/companies/company_6154268'
            target='_blank'
            rel='noreferrer'
          >
            <button
              className='nav-button button'
              style={{ height: '70px', width: '285px' }}
              type='button'
            >
              <div className='nav-buttonText'>
                Wantedly 採用ページ
                <div className='arrow right'></div>
              </div>
            </button>
          </a>
        </div>
      </div>
    )
  }

  private readonly renderValuesSection = (): ReactElement => {
    return (
      <div className='careers-values'>
        <div className='container title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>VALUES</span>
            <span className='subtitle-with-bg'>私たちのバリュー</span>
          </span>
        </div>
        <div className='careers-valueCards section-animation container'>
          {values.map((value: ValueDto) => {
            return <ValuesCard key={value.index} value={value} />
          })}
        </div>
      </div>
    )
  }

  private readonly renderEnvSection = (): ReactElement => {
    return (
      <div className='container careers-zone'>
        <div>
          <div className='careers-envTitleWrapper title-wrapper'>
            <span className='title-with-bg-wrapper'>
              <i className='title-animation'></i>
              <span className='title-with-bg'>ENVIRONMENT</span>
              <span className='subtitle-with-bg'>職場環境</span>
            </span>
          </div>
        </div>
        <div className='careers-env align-items-center'>
          <div className='careers-content careers-envContent section-animation'>
            <div>
              <h1>
                個性を伸ばし、結集させる。
                <br />
                その力は新たな価値を創造する。
              </h1>
            </div>
            <p className='mb-0'>IVAは企業としてまだ若く、急速に成長しています。</p>
            <p className='mb-0'>
              めまぐるしく変化するこの段階において、常に挑戦し変化することを楽しめる人材が必要です。
            </p>
            <p className='mb-0'>
              IVAで新しい取り組みに挑戦し、世の中へ”ワクワク”を届けるために、あなたの力を発揮してください。
            </p>
            <p className='mb-0'>
              IVAでは一人ひとりの持つポテンシャルにスポットライトを当てた職場環境を作っています。
            </p>
            <p className='mb-0'>
              すべての個人に向き合い、多様な経験・知識・意見を結集し、挑戦する。
            </p>
            <p className='mb-0'>ぜひ、この道のりを、一緒に楽しんでいきましょう。</p>
          </div>
          <div></div>
          <div className='careers-carousel section-animation'>
            <Swiper
              autoplay={{
                delay: animationDelayMilliseconds,
                disableOnInteraction: false
              }}
              className='careers-carouselSwiper'
              speed={1000}
              modules={[EffectFade, Autoplay]}
              effect={'fade'}
              loop={true}
            >
              <SwiperSlide>
                <img alt='careers-environment-slide' src={images.careerSlide1} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt='careers-environment-slide' src={images.careerSlide2} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt='careers-environment-slide' src={images.careerSlide3} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt='careers-environment-slide' src={images.careerSlide4} />
              </SwiperSlide>
              <SwiperSlide>
                <img alt='careers-environment-slide' src={images.careerSlide5} />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    )
  }

  private readonly renderCultureSection = (): ReactElement => {
    return (
      <div className='container careers-cultures'>
        <div>
          <div className='careers-cultureTitleWrapper title-wrapper'>
            <span className='title-with-bg-wrapper'>
              <i className='title-animation'></i>
              <span className='title-with-bg'>CULTURE</span>
              <span className='subtitle-with-bg'>カルチャー</span>
            </span>
          </div>
        </div>
        <div className='row align-items-center careers-cultures-content'>
          <div className='careers-content col-sm-7 section-animation order-1 order-sm-0'>
            <div>
              <h1>
                価値やポテンシャルを
                <br />
                最大限に開放していくことが
                <br className='d-block d-sm-none' />
                IVAの使命
              </h1>
            </div>
            <p className='mb-0'>
              一人ひとりが卓越した能力を持ち、日々の学びを怠らない姿勢は、個人のみならずチームに良い影響を及ぼします。
              私たちは「期待の一歩先」「常識の一歩先」を追求し続けます。
              <br />
              スピードは価値です。業務を能率化し、あらゆる局面において常に最速を目指します。これまでにない革新的な存在であり続けることを目指します。前例にとらわれず挑戦し、大胆に、リスクを恐れず創造した価値は、やがて歴史となります。
            </p>
          </div>
          <div className='col-sm-5 order-0 order-sm-1'>
            <div className='culture-imgBox'>
              <img className='culture-img active' src={images.ivaArtBg1} />
              <img className='culture-img' src={images.ivaArtBg2} />
              <img className='culture-img' src={images.ivaArtBg3} />
              <img className='culture-img' src={images.ivaArtBg4} />
              <img className='culture-img-default' src={images.ivaArtBg0} />
            </div>
          </div>
        </div>
        <div className='row align-items-center'>
          <div className='col-sm-3 careers-culture-feature active'>
            <h3>
              <span>
                <i>Entrepreurship</i>
              </span>
            </h3>
            <p>
              「起業家マインド」を育む、
              <br />
              競争に強い骨太なビジネスパーソンに。
            </p>
          </div>
          <div className='col-sm-3 careers-culture-feature'>
            <h3>
              <span>
                <i>Enthusiasm</i>
              </span>
            </h3>
            <p>
              {' '}
              「熱中」して成し遂げる。
              <br />
              熱く働けば、未来が変わる。
            </p>
          </div>
          <div className='col-sm-3 careers-culture-feature'>
            <h3>
              <span>
                <i>Diversity</i>
              </span>
            </h3>
            <p>
              {' '}
              「多様性」こそがパワー
              <br />
              イノベーションを生み出し続けるチーム
            </p>
          </div>
          <div className='col-sm-3 careers-culture-feature'>
            <h3>
              <span>
                <i>Openness</i>
              </span>
            </h3>
            <p>
              {' '}
              最高に「オープネス」、
              <br />
              常にフェアでオープンなコミュニケーション
            </p>
          </div>
        </div>
      </div>
    )
  }

  private readonly renderCommunicationSection = (): ReactElement => {
    return (
      <div className='careers-commBg'>
        <div className='careers-comm container'>
          <div className='container title-wrapper'>
            <span className='title-with-bg-wrapper'>
              <div className='title-reverse-animation'>
                <div className='title-with-bg'>COMMUNICATION</div>
                <div className='subtitle-with-bg'>コミュニケーション</div>
              </div>
            </span>
          </div>
          <div className='section-animation'>
            <p className='careers-commContent'>
              社内ではオンラインツールを活用し、プロジェクトやチームを横断したコミュニケーション・ナレッジ共有が積極的に行われています。日本や台湾、中国といったIVAの拠点だけでなく、外部のパートナーとのコラボレーションも活発に行っています。
            </p>
            <div className='careers-logos'>
              <img alt='slack-logo' id='comm-slack-logo' src={images.slackLogo} />
              <img alt='notion-logo' id='comm-notion-logo' src={images.notionLogo} />
              <img alt='zoom-logo' id='comm-zoom-logo' src={images.zoomLogo} />
              <img alt='asana-logo' id='comm-asana-logo' src={images.asanaLogo} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  private readonly renderRecruitingSection = (): ReactElement => {
    return (
      <Fragment>
        <div className='careers-recruiting container'>
          <div className='careers-content section-animation'>
            <h1>
              仲間を探すプロセスに、
              <br />
              妥協しない
            </h1>
            <p className='mb-4'>
              IVAは「物事を多角的に理解することこそが、最適な意思決定に繋がる」という考えを大切にしています。このため、私たちは「会話」を重視し、採用においてもご応募いただく皆さまとの会話の機会を多く設けています。
            </p>
            <p>
              相互理解を深め、「一緒に楽しいことができそうだ」と確信したうえでJoinすることは、大きな価値を創出するためには重要です。そのため、私たちの採用プロセスは皆さまにとっても価値のあるものであると考えています。
            </p>
          </div>
          <div className='careers-recruitingWrapper'>
            <div className='careers-recruitingTitleWrapper'>
              <div className='title-reverse-animation'>
                <div className='title-with-bg'>
                  <div>
                    RECRUITING<br className='d-block d-sm-none'></br>PHILOSOPHY
                  </div>
                </div>
                <div className='subtitle-with-bg'>採用フィロソフィー</div>
              </div>
              <div></div>
            </div>
            <div
              className='section-animation'
              style={{ display: 'inline-table', marginTop: '34px' }}
            >
              <p className='careers-recruitingStepsTitle'>採用フロー</p>
              <RecruitingSteps />
              <p className='careers-recruitingNote'>
                入社後のミスマッチを双方で防ぐため、必要に応じて採用フローを変更する場合があります。
              </p>
            </div>
          </div>
        </div>
        <div className='careers-contactUs'>
          <a href='https://herp.careers/v1/iva0403' target='_blank' rel='noreferrer'>
            <button
              className='nav-button button'
              style={{ height: '70px', width: '285px' }}
              type='button'
            >
              <div className='nav-buttonText'>
                職種を見る
                <div className='arrow right'></div>
              </div>
            </button>
          </a>
          <a
            href='https://www.wantedly.com/companies/company_6154268'
            target='_blank'
            rel='noreferrer'
          >
            <button
              className='nav-button button'
              style={{ height: '70px', width: '285px' }}
              type='button'
            >
              <div className='nav-buttonText'>
                Wantedly 採用ページ
                <div className='arrow right'></div>
              </div>
            </button>
          </a>
        </div>
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = Array.from(
      document.querySelectorAll('.title-animation')
    ) as HTMLElement[]
    const reverseAnimationElement = Array.from(
      document.querySelectorAll('.title-reverse-animation')
    ) as HTMLElement[]
    const windowHeight = window.innerHeight

    const elements = [...animationElements, ...reverseAnimationElement]
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i]
      if (element) {
        const elementTop = element.getBoundingClientRect().top
        const elementVisible = 10

        if (elementTop < windowHeight - elementVisible) {
          element.classList.add('active')
        } else {
          element.classList.remove('active')
        }
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }
}

export default Careers
