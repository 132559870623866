import React, { Component, type ReactElement } from 'react'
import './style.scss'

interface Props {
  height: string
  label: string
  path: string
  width: string
}

class NavigationButton extends Component<Props> {
  render(): ReactElement {
    const { height, label, path, width } = this.props

    return (
      <a href={`/${path}`} referrerPolicy='no-referrer'>
        <button className='nav-button button' style={{ height, width }} type='button'>
          <div className='nav-buttonText'>
            {label}
            <div className='arrow right'></div>
          </div>
        </button>
      </a>
    )
  }
}

export default NavigationButton
