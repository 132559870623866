import React, { Component, type ReactElement } from 'react'
// import HamburgerMenu from '../../HamburgerMenu/HamburgerMenu'
import images from '../../images'
import Header from '../Header'
import HeaderHandler from '../HeaderHandler/HeaderHandler'
// import HamburgerMenuHandler from '../HeaderHandler/HamburgerMenuHandler'
import './style.css'

interface Props {
  shouldShowBlackLogo: boolean
}

export const headerWrapperId = 'headerWrapper'
class HeaderWrapper extends Component<Props> {
  render(): ReactElement {
    const { shouldShowBlackLogo } = this.props

    return (
      <div id={headerWrapperId}>
        <Header handler={new HeaderHandler(shouldShowBlackLogo)} />
        {/* <HamburgerMenu handler={new HamburgerMenuHandler(shouldShowBlackLogo)} /> */}

        <div id='main-content-mobile-logo'>
          {shouldShowBlackLogo ? (
            <img alt='logo' className='header-logo' src={images.blackLogo} />
          ) : (
            <img alt='logo' className='header-logo' src={images.logo} />
          )}
        </div>
      </div>
    )
  }
}

export default HeaderWrapper
