import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import images from '../../common/images'
import Title from '../../common/Title/Title'
import ContactCategory from './ContactCategory/ContactCategory'
import BackToTop from '../../common/BackToTop/BackToTop'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import AreaCodeSelect from './AreaCodeSelect/AreaCodeSelect'
import './style.scss'

interface State {
  currentSelectedIndex: number | null
  sendEmailVerifyMsg: {
    name: string
    name2: string
    email: string
    question: string
    // phoneNumber: string
  }
  inputField: {
    name: string
    name2: string
    email: string
    question: string
    country_code: number
    tel: string
    title: string
    company: string
    subject: string
    contact_category_id: number
  }
}

class ContactUs extends Component<unknown, State> {
  state = {
    currentSelectedIndex: 1,
    sendEmailVerifyMsg: {
      name: '',
      name2: '',
      email: '',
      question: ''
      // phoneNumber: '',
    },
    inputField: {
      name: '',
      name2: '',
      email: '',
      question: '',
      country_code: 81,
      tel: '',
      title: '',
      company: '',
      subject: '',
      contact_category_id: 1
    }
  }

  componentDidMount(): void {
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜お問い合わせ'
    if (localStorage.getItem('contact')) {
      const data = JSON.parse(localStorage.getItem('contact') ?? '')
      this.setState({
        inputField: {
          ...data
        }
      })
    }
  }

  render(): ReactElement {
    const { currentSelectedIndex, sendEmailVerifyMsg, inputField } = this.state
    // const data = JSON.parse(localStorage.getItem('contact') ?? '')

    return (
      <Fragment>
        <div className='contactUs short-page-adjustment main-content'>
          <BackToTop />
          <Title
            title='CONTACT US'
            subtitle='IVAへのご意見・ご質問は'
            subtitle2='こちらをご利用ください'
          />
          <div className='contactUs-content content-padding container'>
            <div>
              <p style={{ marginBottom: '0' }}>
                お問い合わせの内容によっては返信に時間がかかる場合や、回答を差し控えさせていただく場合もございます。
              </p>
              <p>
                フェイクバスターズの個別鑑定案件に関するお問い合わせはフェイクバスターズの鑑定ページ内よりお問い合わせください。また、土・日、祝日、年末年始休業期間等は、翌営業日以降の対応とさせていただきます。あらかじめご了承ください。
              </p>
              <h3>お客様の情報</h3>
              <div id='contactUs-form' className='form-content'>
                <div className='contactUs-doubleInputRow'>
                  <section>
                    <input
                      placeholder='お名前（必須）'
                      type='text'
                      name='name'
                      onChange={this.onChange}
                      value={inputField.name}
                    />
                    <span className='contactUs-verifyMsg'>{sendEmailVerifyMsg.name}</span>
                  </section>
                  <section>
                    <input
                      placeholder='ふりがな（必須）'
                      type='text'
                      name='name2'
                      onChange={this.onChange}
                      value={inputField.name2}
                    />
                    <span className='contactUs-verifyMsg'>{sendEmailVerifyMsg.name2}</span>
                  </section>
                </div>
                <div className='contactUs-doubleInputRow'>
                  <section>
                    <input
                      className='contactUs-emailInput'
                      placeholder='メールアドレス （必須）'
                      type='email'
                      name='email'
                      onChange={this.onChange}
                      value={inputField.email}
                    />
                    <span className='contactUs-verifyMsg'>{sendEmailVerifyMsg.email}</span>
                  </section>
                  <div className='contactUs-phoneNumber'>
                    <section>
                      <AreaCodeSelect
                        countryCode={inputField.country_code}
                        onCountryCodeChange={this.handleChangeCountryCode}
                      />
                      <input
                        placeholder='電話番号'
                        type='number'
                        name='tel'
                        onChange={this.onChange}
                        value={inputField.tel || ''}
                      />
                    </section>
                    {/* <span className='contactUs-verifyMsg' >{sendEmailVerifyMsg.phoneNumber}</span> */}
                  </div>
                </div>
                <div className='contactUs-doubleInputRow'>
                  <input
                    placeholder='会社名'
                    type='text'
                    name='company'
                    onChange={this.onChange}
                    value={inputField.company}
                  />
                  <input
                    placeholder='部署名'
                    type='text'
                    name='title'
                    onChange={this.onChange}
                    value={inputField.title}
                  />
                </div>
              </div>
            </div>
            <div>
              <h3>お問い合わせ内容</h3>
              <p>
                以下の項目の中から該当するカテゴリーを選択し、フォームにお問い合わせ内容をご記入ください。
              </p>
              <div className='contactUs-categories'>
                <ContactCategory
                  icon={currentSelectedIndex === 1 ? images.checkSelected : images.checked}
                  isSelected={currentSelectedIndex === 1}
                  onClick={async () => await this.selectCategory(1)}
                  title='事業・サービスについて'
                  titleSecondLine='（個人のお客様）'
                  content='IVAの事業やサービス全般に関するお問い合わせはこちらを選択してください'
                />
                <ContactCategory
                  icon={currentSelectedIndex === 2 ? images.people : images.peopleChecked}
                  isSelected={currentSelectedIndex === 2}
                  onClick={async () => await this.selectCategory(2)}
                  title='採用情報について'
                  titleSecondLine='（採用希望者）'
                  content='採用募集に関するご質問についてはこちらを選択してください'
                />
                <ContactCategory
                  icon={currentSelectedIndex === 3 ? images.buildingChecked : images.building}
                  isSelected={currentSelectedIndex === 3}
                  onClick={async () => await this.selectCategory(3)}
                  title='協業・アライアンス'
                  titleSecondLine='（法人のお客様）'
                  content='IVAでは共に新たな価値を生み出すビジネスパートナーを随時募集しています'
                />
                <ContactCategory
                  icon={currentSelectedIndex === 4 ? images.cameraChecked : images.camera}
                  isSelected={currentSelectedIndex === 4}
                  onClick={async () => await this.selectCategory(4)}
                  title='メディア掲載・取材'
                  titleSecondLine='（報道関係者）'
                  content='新聞・雑誌・TVなど各メディアの取材のご依頼はこちらを選択してください'
                />
                <ContactCategory
                  icon={currentSelectedIndex === 5 ? images.handshakeChecked : images.handshake}
                  isSelected={currentSelectedIndex === 5}
                  onClick={async () => await this.selectCategory(5)}
                  title='投資をご検討中の方'
                  titleSecondLine='（法人・個人投資家）'
                  content='IVA株式会社への投資をご検討の方（法人・個人様）は、お気軽にご連絡ください'
                />
                <ContactCategory
                  icon={currentSelectedIndex === 6 ? images.infoChecked : images.info}
                  isSelected={currentSelectedIndex === 6}
                  onClick={async () => await this.selectCategory(6)}
                  title='その他に'
                  titleSecondLine='ついて'
                  content='その他のお問い合わせについてはこちらを選択してください'
                />
              </div>
            </div>
            <textarea
              id='contactUs-question'
              className='contactUs-textArea'
              placeholder='お問い合わせ内容（必須）'
              onChange={this.onChangeTextArea}
              value={inputField.question}
            ></textarea>
            <span className='contactUs-verifyMsg'>{sendEmailVerifyMsg.question}</span>
            <div className='contactUs-navigation'>
              {/* <a href='/contactdone' referrerPolicy='no-referrer' > */}
              <button
                className='nav-button button'
                style={{ height: '70px', width: '285px' }}
                type='button'
                onClick={this.sendEmail}
              >
                <div className='nav-buttonText'>
                  入力内容を確認する
                  <div className='arrow right'></div>
                </div>
              </button>
              {/* </a> */}
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }

  private readonly selectCategory = async (index: number): Promise<any> => {
    this.setState({
      currentSelectedIndex: index,
      inputField: {
        ...this.state.inputField,
        contact_category_id: index
      }
    })
  }

  private readonly handleChangeCountryCode = (newVal: number): void => {
    this.setState({
      inputField: {
        ...this.state.inputField,
        country_code: newVal
      }
    })
  }

  public onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const type = e.currentTarget.name
    if (type === 'name') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          name: e.currentTarget.value
        }
      })
    }
    if (type === 'name2') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          name2: e.currentTarget.value
        }
      })
    }
    if (type === 'email') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          email: e.currentTarget.value
        }
      })
    }
    if (type === 'tel') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          tel: e.currentTarget.value
        }
      })
    }
    if (type === 'title') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          title: e.currentTarget.value
        }
      })
    }
    if (type === 'company') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          company: e.currentTarget.value
        }
      })
    }
    if (type === 'question') {
      this.setState({
        inputField: {
          ...this.state.inputField,
          question: e.currentTarget.value
        }
      })
    }
  }

  public onChangeTextArea = (e: React.FormEvent<HTMLTextAreaElement>): void => {
    this.setState({
      inputField: {
        ...this.state.inputField,
        question: e.currentTarget.value
      }
    })
    console.log(e.currentTarget.value)
  }

  public sendEmail = (): void => {
    const { name, name2, question, email } = this.state.inputField
    const isVerifyExist = Boolean(name && name2 && question && email)
    const isAllowedEmail =
      email.search(/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/) !== -1
    const isAllowedName2 = name2.search(/^[\u3040-\u309F\u30A0-\u30FFー]+$/) !== -1

    this.setState({
      sendEmailVerifyMsg: {
        ...this.state.sendEmailVerifyMsg,
        name: !name ? 'お名前をご記入ください' : '',
        name2: !name2
          ? 'ふりがなをご記入ください'
          : !isAllowedName2
          ? 'ふりがなのみ入力してください'
          : '',
        question: !question ? 'お問い合わせ内容をご記入ください' : '',
        email: !email
          ? 'メールアドレスをご記入ください'
          : !isAllowedEmail
          ? 'メール形式を確認してください'
          : ''
        // tel: ''
      }
    })

    if (!isVerifyExist || !isAllowedEmail || !isAllowedName2) {
      const targetDom: string | null =
        !question && name && name2 && !this.state.sendEmailVerifyMsg.email
          ? 'contactUs-question'
          : 'contactUs-form'

      const ele = document.getElementById(targetDom) as HTMLElement
      ele?.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
    } else {
      window.location.href = '/contactconfirm'
      localStorage.setItem('contact', JSON.stringify(this.state.inputField))
    }
  }
}

export default ContactUs
