import { HEADER_HEIGHT, MOBILE_BREAKPOINT } from './HeaderHandler'
import type IHeaderHandler from './IHeaderHandler'

class HamburgerMenuHandler implements IHeaderHandler {
  constructor(
    public readonly shouldShowBlackLogo: boolean
  ) {}

  public handleNavBarOnScroll = (): void => {
    const menu = document.getElementById('hamburgerMenu') as HTMLElement
    const logo = document.querySelector('.hamburgerMenu-logoLink') as HTMLElement
    const blackLogo = document.querySelector('.hamburgerMenu-blackLogoLink') as HTMLElement
    const toggleIcon = document.querySelector('.toggle-icon') as HTMLElement

    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      if (window.pageYOffset > HEADER_HEIGHT) {
        // const bars = this.shouldShowBlackLogo ? Array.from(document.querySelectorAll('.hamburgerMenuWrapper-black > div')) as HTMLElement[] : Array.from(document.querySelectorAll('.hamburgerMenuWrapper > div')) as HTMLElement[]
        // if (bars.length) {
        //   bars.forEach(bar => bar.style.backgroundColor = 'black')
        // }

        if (toggleIcon && !this.shouldShowBlackLogo) {
          toggleIcon.classList.replace('more-white', 'more-black')
        }

        if (menu) {
          menu.style.backgroundColor = 'white'
        }

        if (logo) {
          logo.style.display = 'none'
        }

        if (blackLogo) {
          blackLogo.style.display = 'block'
        }
      } else {
        // const bars = this.shouldShowBlackLogo ? Array.from(document.querySelectorAll('.hamburgerMenuWrapper-black > div')) as HTMLElement[] : Array.from(document.querySelectorAll('.hamburgerMenuWrapper > div')) as HTMLElement[]
        // if (bars) {
        //   this.shouldShowBlackLogo ? bars.forEach(bar => bar.style.backgroundColor = 'black') : bars.forEach(bar => bar.style.backgroundColor = 'white')
        // }

        if (toggleIcon && !this.shouldShowBlackLogo) {
          toggleIcon.classList.replace('more-black', 'more-white')
        }

        if (menu) {
          this.shouldShowBlackLogo ? menu.style.backgroundColor = 'white' : menu.style.backgroundColor = 'transparent'
        }

        if (logo && blackLogo) {
          if (this.shouldShowBlackLogo) {
            logo.style.display = 'none'
            blackLogo.style.display = 'block'
          } else {
            logo.style.display = 'block'
            blackLogo.style.display = 'none'
          }
        }
      }
    }
  }
}

export default HamburgerMenuHandler
