import React, { Component, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import NavigationButton from '../../common/NavigationButton/NavigationButton'
import Title from '../../common/Title/Title'
import images from '../../common/images'
import './style.scss'

class ContactUsDone extends Component {
  render(): ReactElement {
    return (
      <div className='contactUsDone short-page-adjustment'>
        <Title title='CONTACT US' subtitle='お問い合わせ' subtitle2='' />
        <div className='contactUs-content content-padding'>
          <img alt='contact-us-form-done' src={images.doneCheckMark} />
          <h2 className='contactUsDone-title'>お問い合わせを受け付けいたしました</h2>
          <p>
            ご入力いただいたお問い合わせ内容の送信が完了いたしました。内容を確認し、担当の部署より回答を致しますので、今しばらくお待ちください。
          </p>
          <div className='contactUs-navigation'>
            <NavigationButton height='70px' label='トップページへ戻る' path='' width='285px' />
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ContactUsDone
