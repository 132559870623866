import React, { Component, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import Title from '../../common/Title/Title'
// import AreaCodeSelect from './AreaCodeSelect/AreaCodeSelect'
// import { Transition } from 'react-transition-group'
import './style.scss'

class ContactUsConfirm extends Component {
  inputField = {
    name: '',
    name2: '',
    email: '',
    tel: '',
    title: '',
    company: '',
    question: '',
    country_code: 81,
    subject: '',
    contact_category_id: 1
  }

  state = {
    sending: false
  }

  componentDidMount(): void {
    const data = JSON.parse(localStorage.getItem('contact') ?? '')
    this.inputField = data
    this.inputField.title = data.title ? data.title : 'N/A'
    this.inputField.subject = data.subject ? data.subject : 'N/A'
    this.inputField.company = data.company ? data.company : 'N/A'
  }

  render(): ReactElement {
    const data = JSON.parse(localStorage.getItem('contact') ?? '')
    const categoryMap = {
      1: '事業・サービスについて',
      2: '採用情報について',
      3: '協業・アライアンス',
      4: 'メディア掲載・取材',
      5: '投資をご検討中の方',
      6: 'その他'
    }
    return (
      <div className='contactUs short-page-adjustment'>
        <Title title='CONTACT US' subtitle='IVAへのご意見・' subtitle2='ご質問はこちらをご利用ください' />
        <div className='contactUs-confirm-content'>
          <div>以下の内容でお問い合わせを送信いたします。内容にお間違いがないかご確認ください。</div>
          <h3 className='contactUsConfirm-title'>お客様の情報</h3>

          <div>
            <div className="form-zone">
              <div className='form-title'>お名前：</div>
              <div>{ data.name || '記入なし' }</div>
            </div>
            <div className="form-zone">
              <div className='form-title'>ふりがな：</div>
                <div>{ data.name2 || '記入なし' }</div>
            </div>
            <div className="form-zone">
              <div className='form-title'>メールアドレス：</div>
                <div>{ data.email || '記入なし' }</div>
            </div>
            <div className="form-zone">
              <div className='form-title'>電話番号：</div>
              <div>{ `（+${data.country_code}）${data.tel}` }</div>
              {/* <AreaCodeSelect countryCode={this.inputField.country_code} onCountryCodeChange={data.country_code} /> */}
            </div>
            <div className="form-zone">
              <div className='form-title'>会社名：</div>
                <div>{ data.company || '記入なし' }</div>
            </div>
            <div className="form-zone">
              <div className='form-title'>部署名：</div>
                <div>{ data.title || '記入なし' }</div>
            </div>
          </div>

          <h3 className='contactUsConfirm-title'>お問い合わせ内容</h3>
          <p style={{ fontWeight: 'bold' }}>
            {categoryMap[data.contact_category_id as keyof typeof categoryMap] }
          </p>
          <p style={{ lineHeight: '180%' }}>
            { data.question }
          </p>
          <div className='contactUs-navigation'>
            <a href='/contact' referrerPolicy='no-referrer' >
              <button className='nav-white-button button' style={{ height: '70px', width: '285px' }} type='button'>
                <div className='nav-buttonText'>
                  入力画面に戻る
                  <div className='arrow right'></div>
                </div>
              </button>
            </a>
            <button className='nav-button button' style={{ height: '70px', width: '285px' }} type='button' onClick={this.sendEmail}>
              <div className='nav-buttonText'>
                送信する
                <div className='arrow right'></div>
              </div>
            </button>
          </div>
        </div>
        <Footer />
      </div>
    )
  }

  public sendEmail = (): void => {
    const { sending } = this.state
    if (sending) {
      return
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.inputField)
    }
    this.setState({ sending: true })
    fetch(`${process.env.REACT_APP_API}/iva/contact`, requestOptions)
    .then(async res => await res.json())
    .then(data => {
      if (data.code === 200) {
        window.location.href = '/contactdone'
        localStorage.removeItem('contact')
      }
    })
    .catch(e => {
        console.log(e)
    })
    .finally(() => {
      this.setState({ sending: false })
    })
  }
}

export default ContactUsConfirm
