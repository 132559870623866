import type IHeaderHandler from './IHeaderHandler'

export const MOBILE_BREAKPOINT = 776
export const HEADER_HEIGHT = 70
class HeaderHandler implements IHeaderHandler {
  private readonly shouldShowBlack: boolean

  constructor(
    shouldShowBlack: boolean
  ) {
    this.shouldShowBlack = shouldShowBlack
  }

  public get shouldShowBlackLogo(): boolean {
    return this.shouldShowBlack
  }

  public handleNavBarOnScroll = (): void => {
    const header = document.querySelector('#header') as HTMLElement
    const headerLinks = Array.from(document.querySelectorAll('.header-navigation > a')) as HTMLElement[]
    const blackLogo = document.querySelector('.header-blackLogoLink') as HTMLElement
    const logo = document.querySelector('.header-logoLink') as HTMLElement
    const fakeLogo = document.querySelector('.fakeBustersLink .fakeBustersLink-link') as HTMLElement
    const blackFakeLogo = document.querySelector('.fakeBustersLink .black-fakeBustersLink-link') as HTMLElement

    if (window.innerWidth > MOBILE_BREAKPOINT) {
      if (window.pageYOffset > HEADER_HEIGHT) {
        headerLinks.forEach((link: HTMLElement) => link.style.color = 'black')

        if (blackLogo) {
          blackLogo.style.display = 'block'
          blackFakeLogo.style.display = 'block'
        }

        if (logo) {
          logo.style.display = 'none'
          fakeLogo.style.display = 'none'
        }

        if (header) {
          header.style.backgroundColor = 'white'
          header.style.display = 'block'
          header.style.borderBottom = '1px solid rgba(0, 0, 0, 0.35)'
        }
      } else {
        if (!this.shouldShowBlack) {
          headerLinks.forEach((link: HTMLElement) => link.style.color = 'white')

          if (header) {
            header.style.backgroundColor = 'transparent'
            header.style.borderBottom = '1px solid rgba(255, 255, 255, 0.35)'
          }

          if (blackLogo) {
            blackLogo.style.display = 'none'
            blackFakeLogo.style.display = 'none'
          }

          if (logo) {
            logo.style.display = 'block'
            fakeLogo.style.display = 'block'
          }
        } else {
          headerLinks.forEach((link: HTMLElement) => link.style.color = 'black')
        }
      }
    } else {
      header.style.display = 'none'
    }
  }
}

export default HeaderHandler
