import React, { Component, Fragment, type ReactElement } from 'react'
import Title from '../../common/Title/Title'
import Footer from '../../common/Footer/Footer'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import '../../common/content.css'

class PrivacyPolicy extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <div className='privacyPolicy short-page-adjustment main-content'>
          <Title title='Privacy policy' subtitle='プライバシーポリシー' subtitle2='' />
          <div className='privacyPolicy-content content container'>
            <div className='content-intro'>
              <p className='mid-para'>
                本プライバシーポリシーは、IVA株式会社（
                <a
                  href='https://www.iva-corporation.com'
                  target='_blank'
                  referrerPolicy='no-referrer'
                  rel='noreferrer'
                >
                  https://www.iva-corporation.com
                </a>
                ）（以下、「当サイト」とします。)の各種サービス（当サイトによる情報提供、各種お問合せの受付等）において、当サイトの訪問者（以下、「訪問者」とします。）の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。
              </p>
            </div>
            <div>
              <h3>1．基本方針</h3>
              <p className='mid-para'>
                当サイトは、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令を遵守し、当サイトで取扱う個人情報の取得、利用、管理を適正に行います。当サイトで収集した情報は、利用目的の範囲内で適切に取り扱います。
              </p>
              <h3>2．適用範囲</h3>
              <p className='mid-para'>
                本プライバシーポリシーは、当サイトにおいてのみ適用されます。
              </p>
              <h3>3．個人情報の取得と利用目的</h3>
              <p className='mid-para'>
                当サイトで取得する訪問者の個人情報と利用目的、保存期間等は下記の通りです。
              </p>
              <p className='mid-para'>3-1．お問い合わせされた際に個人情報を取得します</p>
              <p className='mid-para'>
                当サイトでは、訪問者が当サイトで「お問い合わせ」を利用した際に、以下の個人情報を取得します。
              </p>
              <ol>
                <li>名前</li>
                <li>メールアドレス</li>
                <li>電話番号</li>
                <li>会社名／部署名</li>
                <li>内容</li>
                <li>IPアドレス</li>
              </ol>
              <h4>3-1-1．利用目的について</h4>
              <p className='mid-para'>
                荒し対策やスパム検出に役立てるためと、訪問者の利便性のためです。また、当サイト内で「よくある質問」として紹介させていただく場合もあります。
              </p>
              <h4>3-1-2．保存期間について</h4>
              <p className='mid-para'>
                当サイトに残されたコメントは、コメントとそのメタデータを半永久的に保存します。
              </p>
              <h4>3-1-3. 訪問者は個人情報をブラウザに保存するか選択することが出来ます</h4>
              <p className='mid-para'>
                訪問者は当サイトにコメントを残す際に、お名前、メールアドレス、お持ちのサイト情報（任意）を
                ブラウザに保存するかどうかを選択できます。
              </p>
              <h4>3-2．Cookieによる個人情報の取得</h4>
              <p className='mid-para'>
                当サイトは、訪問者のコンピュータにCookieを送信することがあります。
                Cookie（クッキー）とは、ウェブサイトを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、訪問者のコンピュータにファイルとして保存しておく仕組みです。
              </p>
              <h4>3-2-1．利用目的について</h4>
              <p className='mid-para'>
                訪問者の当サイト閲覧時の利便性を高めるためです。
                たとえば、次回同じページにアクセスするとCookieの情報を使って、ページの運営者は訪問者ごとに表示を変えることができます。
                <br></br>
                たとえばあるサイトを利用していて、初回はログインパスワードを入力する画面が表示されたけど、2回目以降はログイン画面は表示されずにアクセスできた、という経験ありませんか？それはCookieによるものです。
                <br></br>
                訪問者がブラウザの設定でCookieの送受信を許可している場合、ウェブサイトは、訪問者のブラウザからCookieキーを取得できます。
                <br></br>
                なお、訪問者のブラウザはプライバシー保護のため、そのウェブサイトのサーバーが送受信したCookieのみを送信します。
              </p>
              <h4>3-2-2．保存期間について</h4>
              <p className='mid-para'>
                当サイトに残されたコメントの Cookie は、1年間保存されます。
              </p>
              <h4>3-2-3．第三者によるCookie情報の取得について</h4>
              <p className='mid-para'>
                当サイトでは、グーグル株式会社やヤフー株式会社などをはじめとする第三者から配信される広告が掲載される場合があり、これに関連して当該第三者が訪問者のCookie情報等を取得して、利用している場合があります。
                <br></br>
                当該第三者によって取得されたCookie情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。
              </p>
              <h4>3-2-4．第三者へのCookie情報等の広告配信の利用停止について</h4>
              <p className='mid-para'>
                訪問者は、当該第三者のウェブサイト内に設けられたオプトアウト（個人情報を第三者に提供することを停止すること）ページにアクセスして、当該第三者によるCookie情報等の広告配信への利用を停止することができます。
              </p>
              <h4>3-2-5．Cookie情報の送受信の許可・拒否について</h4>
              <p className='mid-para'>
                訪問者は、Cookieの送受信に関する設定を「すべてのCookieを許可する」、「すべてのCookieを拒否する」、「Cookieを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります
                Cookieに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。
                <br></br>
                すべてのCookieを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。
              </p>
              <h3>4．個人情報の管理</h3>
              <p className='mid-para'>
                当サイトは、訪問者からご提供いただいた情報の管理について、以下を徹底します。
              </p>
              <h4>4-1. 情報の正確性の確保</h4>
              <p className='mid-para'>
                訪問者からご提供いただいた情報については、常に正確かつ最新の情報となるよう努めます。
              </p>
              <h4>4-2. 安全管理措置</h4>
              <p className='mid-para'>
                当サイトは、個人情報の漏えいや滅失又は棄損を防止するために、適切なセキリュティ対策を実施して個人情報を保護します。
              </p>
              <h4>4-3. 個人情報の廃棄</h4>
              <p className='mid-para'>個人情報が不要となった場合には、すみやかに廃棄します。</p>
              <h4>4-4. 個人情報の開示、訂正、追加、削除、利用停止</h4>
              <p className='mid-para'>
                訪問者ご本人からの個人情報の開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
              </p>
              <p className='mid-para'>
                上記を希望される場合は、以下のメールアドレスまでお問い合わせください。
              </p>
              <p className='mid-para'>
                メールアドレス：{' '}
                <a href='mailto:support@fakebusters-iva.jp'>support@fakebusters-iva.jp</a>
              </p>
              <h3>5．個人情報の第三者への提供について</h3>
              <p className='mid-para'>
                当サイトは、訪問者からご提供いただいた個人情報を、訪問者本人の同意を得ることなく第三者に提供することはありません。また、今後第三者提供を行うことになった場合には、提供する情報と提供目的などを提示し、訪問者から同意を得た場合のみ第三者提供を行います。
              </p>
              <h3>6．未成年の個人情報について</h3>
              <p className='mid-para'>
                未成年者が当サイトでお問い合わせフォームから問い合わせをされたりする場合は必ず親権者の同意を得るものとし、コメントやお問い合わせをされた時点で、当プライバシーポリシーに対して親権者の同意があるものとみなします。
              </p>
              <h3>7．お問い合わせ先</h3>
              <p className='mid-para'>
                当サイト、又は個人情報の取扱いに関しては、下記のメールアドレスにてお問い合わせください。
              </p>
              <p className='mid-para'>当サイト運営会社：IVA株式会社</p>
              <p className='mid-para'>
                メールアドレス：
                <a href='mailto:support@fakebusters-iva.jp'>support@fakebusters-iva.jp</a>
              </p>
              <h3>8．アクセス解析ツールについて</h3>
              <p className='mid-para'>
                当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
              </p>
              <p className='mid-para'>
                このGoogleアナリティクスはアクセス情報の収集のためにCookieを使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。
              </p>
              <p className='mid-para'>
                GoogleアナリティクスのCookieは、26ヶ月間保持されます。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
              </p>
              <p className='mid-para'>
                Googleアナリティクスの利用規約に関して確認したい場合は、コチラをクリックしてください。また、「ユーザーが
                Google パートナーのサイトやアプリを使用する際の Google
                によるデータ使用」に関して確認したい場合は、コチラをクリックしてください。
              </p>
              <h3>9．第三者配信の広告サービスについて</h3>
              <p className='mid-para'>
                当サイトは、第三者配信の広告サービス「Google
                Adsense（グーグルアドセンス）」を利用しています。
              </p>
              <p className='mid-para'>
                Googleなどの第三者広告配信事業者は、訪問者の興味に応じた広告を表示するために、Cookie（当サイトの訪問者が当サイトや他のサイトにアクセスした際の情報など）を使用することがあります。
              </p>
              <p className='mid-para'>
                訪問者は、広告設定で訪問者に合わせた広告（以下、「パーソナライズド広告」とします。）を無効にすることが出来ます。また、www.aboutads.infoにアクセスすれば、パーソナライズド広告に使われる第三者配信事業者の
                Cookie を無効にできます。
              </p>
              <p className='mid-para'>
                第三者配信による広告掲載を無効にしていない場合は、第三者配信事業者や広告ネットワークの配信する広告がサイトに掲載されることがあります。
              </p>
              <p className='mid-para'>
                Googleによって広告の第三者配信が認められている広告配信事業者の詳細は、コチラをクリックしてください。
              </p>
              <h3>10．プライバシーポリシーの変更について</h3>
              <p className='mid-para'>
                当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。
              </p>
              <p className='mid-para'>令和5年8月8日 策定</p>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }
}

export default PrivacyPolicy
