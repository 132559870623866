import DateHelper from '../../../common/DateHelper'
import type IFetcher from '../../../common/Fetcher/IFetcher'
import type ISubscriber from '../../../common/ISubscriber'
import type INewsPresenter from './INewsPresenter'
import type NewsDto from './NewsDto'
import type NewsListDto from './NewsListDto'

export const MAX_NEWS_PER_PAGE = 15
class NewsPresenter implements INewsPresenter {
  private news: NewsDto[]
  private topNews: NewsDto[]
  private yearToNews: Map<string, NewsDto[]>
  private loading: boolean
  private selectedYear: string
  private readonly startingIndex: number
  private readonly endingIndex: number
  private pageIndex: number
  private view: ISubscriber | null
  private pagination: { count: number, current_page: number, per_page: number, total: number, total_pages: number }

  constructor(private readonly fetcher: IFetcher) {
    this.loading = true
    this.selectedYear = ''
    this.startingIndex = 0
    this.endingIndex = MAX_NEWS_PER_PAGE + 1
    this.pageIndex = 1
    this.view = null
    this.news = []
    this.topNews = []
    this.yearToNews = new Map()
    this.pagination = { count: 0, current_page: 0, per_page: 0, total: 0, total_pages: 0 }
  }

  public setView(view: ISubscriber): void {
    this.view = view
  }

  private updateView(): void {
    if (this.view) {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
      this.view.update()
    }
  }

  public isLoading(): boolean {
    return this.loading
  }

  public async initialize(isShowLoading: boolean = true): Promise<void> {
    this.loading = isShowLoading
    this.updateView()
    this.news = []
    this.topNews = []
    this.yearToNews = new Map()

    const result2 = await this.fetcher.fetch({
      method: 'GET',
      url: `${process.env.REACT_APP_API}/iva/news-top`
    })
    if (result2?.data.news) {
      this.storeTopNews(result2.data.news)
    }
    const paramsYear = this.selectedYear ? `&year=${this.selectedYear}` : ''
    const result = await this.fetcher.fetch({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/iva/news?page=${this.pageIndex}${paramsYear}`
    })

    if (result?.data.news && Array.isArray(result.data.news)) {
      this.pagination = result.data.pagination
      this.storeNews(result.data.news)
    }
  }

  private storeTopNews(news: any[]): void {
    news.forEach(n => {
      const category = n.category
      const newsItem: NewsDto = {
        content: n.content,
        date: DateHelper.formatDate(n.released_at),
        id: n.id,
        title: n.title,
        subtitle: n.subtitle,
        category: {
          backgroundColor: category.background_color,
          fontColor: category.font_color,
          id: category.id,
          name: category.name
        },
        cover: n.cover,
        link: n.link,
        type: n.type
      }
      this.topNews.push(newsItem)
    })
  }

  private storeNews(news: any[]): void {
    news.forEach(n => {
      const category = n.category
      const newsItem: NewsDto = {
        content: n.content,
        date: DateHelper.formatDate(n.released_at),
        id: n.id,
        title: n.title,
        subtitle: n.subtitle,
        category: {
          backgroundColor: category.background_color,
          fontColor: category.font_color,
          id: category.id,
          name: category.name
        },
        cover: n.cover,
        link: n.link,
        type: n.type
      }
     this.news.push(newsItem)
     this.addNewsToMap(newsItem, DateHelper.getYear(n.released_at))
    })

    this.loading = false
    this.updateView()
  }

  private addNewsToMap(news: NewsDto, year: string): void {
    const newsList = this.yearToNews.get(year)

    if (!newsList) {
      this.yearToNews.set(year, [news])
    } else {
      this.yearToNews.set(year, [...newsList, news])
    }
  }

  public filterByYear(year: number): void {
    this.selectedYear = year ? year.toString() : ''
    this.pageIndex = 1
    this.initialize(false)
  }

  public getCurrentPageIndex(): number {
    return this.pageIndex
  }

  public getPaginatorLength(): number[] {
    const length = Math.ceil(this.pagination.total / this.pagination.per_page)
    const paginator = []

    for (let i = 1; i < length + 1; i++) {
      paginator.push(i)
    }

    return paginator
  }

  public async changePage(index: number): Promise<void> {
    this.news = []
    this.yearToNews = new Map()
    const paramsYear = this.selectedYear ? `&year=${this.selectedYear}` : ''
    const result = await this.fetcher.fetch({
      method: 'GET',
      url: `${process.env.REACT_APP_API}/iva/news?page=${index}${paramsYear}`
    })
    this.storeNews(result.data.news)
  }

  public updatePage(index: number): void {
    this.pageIndex = index
    this.changePage(this.pageIndex)
    // this.startingIndex = index === 1 ? 0 : MAX_NEWS_PER_PAGE * (index - 1) + (index - 1)
    // this.endingIndex = index === 1 ? MAX_NEWS_PER_PAGE : MAX_NEWS_PER_PAGE * (index) + (index - 1)
    this.updateView()
  }

  public goToNextPage(): void {
    const length = Math.ceil(this.pagination.total / this.pagination.per_page)
    if (this.pageIndex === length) {
      return
    }
    this.pageIndex++
    this.changePage(this.pageIndex)
    this.updateView()
  }

  public goToPreviousPage(): void {
    if (this.pageIndex === 1) {
      return
    }
    this.pageIndex--
    this.changePage(this.pageIndex)
    this.updateView()
  }

  public getAllNews(): NewsDto[] {
    return this.news
  }

  public getTopNews(): NewsDto[] {
    return this.topNews
  }

  public getNews(): NewsListDto[] {
    if (this.selectedYear) {
      return [
        {
          year: this.selectedYear,
          news: this.yearToNews.get(this.selectedYear) ?? []
        }
      ]
    }

    const newsList: NewsListDto[] = []
    this.yearToNews.forEach((value: NewsDto[], key: string) => {
      newsList.push({
        year: key,
        news: value
      })
    })

    return newsList.sort((a: NewsListDto, b: NewsListDto) => a.year < b.year ? 1 : -1).slice(this.startingIndex, this.endingIndex)
  }

  public clearView(): void {
    this.view = null
  }
}

export default NewsPresenter
