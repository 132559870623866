import React, { Component, type ReactElement } from 'react'
import type CategoryDto from '../Presenter/CategoryDto'
import './style.scss'

interface Props {
  category: CategoryDto
  id: number
  image: string
  date: string
  preview: string
  tag: string
  link: string
  type: number
}

class NewsCard extends Component<Props> {
  render(): ReactElement {
    const { category, date, id, image, preview, tag, link, type } = this.props

    return (
      <div className='newsCard'>
        { type !== 2 &&
          <div className='newsCard-image'>
            <a href={link || `/newsdetails?id=${id}`} target={link ? '_blank' : ''} rel="noopener noreferrer">
              <img src={image} alt={image} />
            </a>
          </div>
        }
        { type === 2 &&
          <div className='newsCard-image'>
            <img src={image} alt={image} />
          </div>
        }
        <div className='newsCard-contentWrapper'>
          <div className='newsCard-headerWrapper'>
            <p className='newsCard-date'>{date}</p>
            <p className='newsRow-tag' style={{ backgroundColor: category.backgroundColor }}>{tag}</p>
          </div>
          { type !== 2 &&
            <a href={link || `/newsdetails?id=${id}`} target={link ? '_blank' : ''} rel="noopener noreferrer">
              <div className='newsRow-preview'>{preview}</div>
            </a>
          }
          { type === 2 &&
            <div className='newsRow-preview'>{preview}</div>
          }
        </div>
      </div>
    )
  }
}

export default NewsCard
