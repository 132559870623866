import React, { Component, Fragment, type ReactElement } from 'react'
import BackToTop from '../../common/BackToTop/BackToTop'
import Footer from '../../common/Footer/Footer'
import images from '../../common/images'
import NavigationButton from '../../common/NavigationButton/NavigationButton'
import home from '../../images/home.mp4'
// import Services from './Services/Services'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import type INewsPresenter from '../News/Presenter/INewsPresenter'
import NewsCard from '../News/NewsCard/NewsCard'
import type ISubscriber from '../../common/ISubscriber'
import './style.scss'
import type NewsDto from '../News/Presenter/NewsDto'

interface Props {
  newsPresenter: INewsPresenter
}

const MAX_VISIBLE_NEWS_IN_HOME_PAGE = 4
class Home extends Component<Props> implements ISubscriber {
  componentDidMount(): void {
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜ホーム'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
    this.props.newsPresenter.setView(this)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
    this.props.newsPresenter.clearView()
  }

  render(): ReactElement {
    const { newsPresenter } = this.props

    if (newsPresenter.isLoading()) {
      return <LoadingState />
    }

    const news = newsPresenter.getTopNews()
    return (
      <Fragment>
        <div style={{ backgroundColor: '#2f2f2f' }}>
          <LoadingState />
          <div className='home main-content'>
            <Fragment>
              <HeaderWrapper shouldShowBlackLogo={false} />
              <BackToTop />
            </Fragment>
            <div className='home-video'>
              <video autoPlay={true} controls={false} muted loop={true} playsInline width='100%'>
                <source src={home} type='video/mp4' />
                Sorry, your browser does not support videos.
              </video>
              <div className='home-title'>
                <h1>AI xヒトで、世界にもっと輝きを。</h1>
                <p>AI-DRIVEN and HUMAN-POWERED for dynamic future.</p>
              </div>
              <div className='home-scroll' onClick={this.scrollToNews}>
                <span className='home-scrollText'>Scroll</span>
              </div>
            </div>
            <div className='home-news container'>
              <h2 className='h2-en' id='news'>
                NEWS
              </h2>
              <div
                className='home-newsPreview text-link section-animation'
                style={this.setNewsPreviewStyling(news)}
              >
                {news.map((newsDto) => {
                  return (
                    <NewsCard
                      category={newsDto.category}
                      id={newsDto.id}
                      image={newsDto.cover}
                      date={newsDto.date}
                      key={newsDto.id}
                      tag={newsDto.category.name}
                      link={newsDto.link}
                      preview={newsDto.title}
                      type={newsDto.type}
                    />
                  )
                })}
              </div>
              <div className='home-navButtonWrapper'>
                <NavigationButton height='50' label='ニュース一覧を見る' path='news' width='285' />
              </div>
            </div>
            <div className='home-company container'>
              <h2 className='h2-en'>COMPANY</h2>
              <div className='home-companyContent align-items-center'>
                <div className='home-companyImage section-animation'>
                  <img alt='company' src={images.companyBuilding} />
                </div>
                <div></div>
                <div className='home-companyIntro section-animation'>
                  <div className='home-companyTitles'>
                    <h1>
                      55兆円市場をハックし、
                      <br />
                      経済のポテンシャルを解放する
                    </h1>
                    {/* <p className='home-companySubtitle'>
                    IVAはお客さまとともに、社員とともに、社会とともに成長します
                  </p> */}
                  </div>
                  <div className='home-companyIntroContent'>
                    <p className='mb-3'>
                      インターネットを通しての購買活動が一般化する現代において、その利便性を最大限に体験してもらうためには購買活動における「安心」は必要不可欠です。
                    </p>
                    <p>
                      「真贋鑑定のプロフェッショナル」として歩むIVAは、豊かな情報量とそれを具現化させる確かな技術力を柱に、これからの時代の消費者の購買プロセスに変化を与え、経済のポテンシャルを解放します。
                    </p>
                  </div>
                </div>
              </div>
              <div className='home-navButtonWrapper'>
                <NavigationButton height='50' label='会社概要を見る' path='company' width='285' />
              </div>
            </div>
            <div className='home-service'>
              <div className='container'>
                <h2 className='h2-en-white'>SERVICES</h2>
                <div className='home-serviceContent service-content'>
                  <div className='home-buildWithUsImage section-animation'>
                    <a
                      href='https://www.fakebusters-iva.com'
                      referrerPolicy='no-referrer'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img className='service-image' alt='service' src={images.serviceImage1} />
                    </a>
                    <div>
                      <h2 className='service-item-title h2-en-white text-center'>FAKEBUSTERS</h2>
                      <p className='white'>
                        「FAKEBUSTERS（フェイクバスターズ）」はスニーカーやアパレル、トレカ、ラグジュアリーアイテムの真贋鑑定サービスです。
                      </p>
                      <p className='white'>
                        月間40,000件以上の鑑定依頼を、多彩な経歴を持つ鑑定チームと最新鋭の専門機器、AI鑑定技術を駆使して、正確かつ迅速に対応しています。現在までに累計150万件以上のお客様にご利用いただいています。
                      </p>
                    </div>
                  </div>
                  <div className='home-buildWithUsImage section-animation'>
                    <a referrerPolicy='no-referrer' target='_blank' rel='noreferrer'>
                      <img className='service-image' alt='service' src={images.serviceImage3} />
                    </a>
                    <div>
                      <h2 className='service-item-title h2-en-white text-center'>
                        CIRCULAR ECONOMY SOLUTIONS
                      </h2>
                      <p className='white'>
                        IVAではサーキュラーエコノミー（循環型経済）の実現・推進に向けてさまざまなソリューションを展開しています。希少商材の大口調達を専門のバイヤーチームが代行する「ホールセール」、中古スニーカーの商品価値を高める「スニーカークリーニング」事業など、多くのマーケットプレイス・リユース企業様にご利用いただいています。
                      </p>
                    </div>
                  </div>
                  <div className='home-buildWithUsImage section-animation'>
                    <a
                      href='https://www.sneaker-expo.com'
                      referrerPolicy='no-referrer'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <img className='service-image' alt='service' src={images.serviceImage2} />
                    </a>
                    <div>
                      <h2 className='service-item-title h2-en-white text-center'>
                        SNEAKER EXPO (Snex)
                      </h2>
                      <p className='white'>
                        SNEAKER
                        EXPO（Snex）はスニーカーやストリートアイテムをメインに扱うブランドやショップが一堂に介し、展示・販売を行う国内最大級のスニーカーイベントです。
                      </p>
                      <p className='white'>
                        第1回は2022年5月に渋谷ヒカリエホール、第2回は前回の3倍の規模となるパシフィコ横浜で2日間開催しました。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='home-services'>{/* <Services /> */}</div>
            <div className='home-careers container'>
              <h2 className='h2-en'>CAREERS</h2>
              <div className='home-careersContent align-items-center'>
                <div className='home-buildWithUsImage section-animation'>
                  <img alt='careers' src={images.buildWithUs} />
                </div>
                <div></div>
                <div className='home-careersIntro section-animation'>
                  <h1>あなたの才能が、未来を創る</h1>
                  <p className='mb-0'>IVAは企業としてまだ若く、急速に成長しています。</p>
                  <p className='mb-0'>
                    めまぐるしく変化するこの段階において、常に挑戦し変化することを楽しめる人材が必要です。
                    IVAで新しい取り組みに挑戦し、世の中へ”ワクワク”を届けるために、あなたの力を発揮してください。
                  </p>
                  <p className='mb-0'>
                    IVAでは一人ひとりの持つポテンシャルにスポットライトを当てた職場環境を作っています。
                    すべての個人に向き合い、多様な経験・知識・意見を結集し、挑戦する。
                    ぜひ、この道のりを、一緒に楽しんでいきましょう。
                  </p>
                </div>
              </div>
              <div className='home-navButtonWrapper'>
                <NavigationButton height='50' label='採用情報を見る' path='careers' width='285' />
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }

  update = (): void => {
    this.setState({})
  }

  private readonly scrollToNews = (): void => {
    const news = document.getElementById('news') as HTMLElement
    news.scrollIntoView({ behavior: 'smooth' })
    const header = document.getElementById('header') as HTMLElement
    if (header) {
      header.style.display = 'none'
    }
  }

  private readonly setNewsPreviewStyling = (news: NewsDto[]): object => {
    return {
      gridTemplateColumns: `${
        news.length < MAX_VISIBLE_NEWS_IN_HOME_PAGE
          ? `repeat(${news.length}, 1fr)`
          : 'repeat(4, 1fr)'
      }`
    }
  }
}

export default Home
