import React, { Component, type ReactElement } from 'react'
import './style.css'

class LoadingState extends Component {
  render(): ReactElement {
    return (
      <div className='loading'>
        <div className="loader">
          <div className="loader-inner ball-scale-multiple">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    )
  }
}

export default LoadingState
