import React, { Component, type ReactElement } from 'react'
import { headerWrapperId } from '../Header/HeaderWrapper/HeaderWrapper'
import $ from 'jquery'
import 'jquery.easing'
import './style.css'

interface State {
  showButton: boolean
}
class BackToTop extends Component<unknown, State> {
  state = {
    showButton: false
  }

  componentDidMount(): void {
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('scroll', this.handleScroll)
  }

  render(): ReactElement {
    const { showButton } = this.state
    return (
      <div>
        { showButton &&
          <button onClick={this.scrollToTop} id='backToTop'>
            <div className='arrow top'></div>
          </button>
        }
      </div>
    )
  }

  private readonly handleScroll = (): void => {
    const shouldShowButton = window.scrollY > 100
    this.setState({ showButton: shouldShowButton })
  }

  private readonly scrollToTop = (): void => {
      $('body,html').animate({
          scrollTop: 0
      }, 1000, 'easeInOutQuint')
    const header = document.getElementById(headerWrapperId) as HTMLElement
    header.style.display = 'block'
  }
}

export default BackToTop
