// import { cwd } from 'process'
import DateHelper from '../../../../common/DateHelper'
import type IFetcher from '../../../../common/Fetcher/IFetcher'
import type ISubscriber from '../../../../common/ISubscriber'
import type NewsDto from '../../Presenter/NewsDto'
import type INewsDetailsPresenter from './INewsDetailsPresenter'

class NewsDetailsPresenter implements INewsDetailsPresenter {
  private news: NewsDto[]
  private loading: boolean
  private id: number | null
  private view: ISubscriber | null

  constructor(private readonly fetcher: IFetcher) {
    this.id = null
    this.news = []
    this.view = null
    this.loading = true
   // eslint-disable-next-line @typescript-eslint/no-floating-promises
   this.initialize()
  }

  public async initialize(): Promise<void> {
    this.loading = true
    this.updateView()

    this.setNewsId()
    this.news = []
    const result = await this.fetcher.fetch({
        method: 'GET',
        url: `${process.env.REACT_APP_API}/iva/news/${this.id}/detail`
    })

    if (result?.data) {
      this.storeNews(result.data)
    }
  }

  private setNewsId(): void {
    const urlParams = new URLSearchParams(window.location.search)

    if (urlParams.has('id')) {
      this.id = parseInt(urlParams.get('id') ?? '', 10)
    }
  }

  private storeNews(news: any): void {
      news = {
        content: news.content,
        date: DateHelper.formatDate(news.released_at),
        id: news.id,
        title: news.title,
        subtitle: news.subtitle
      }
      this.news = news

      this.loading = false
      this.updateView()
  }

  public setView(view: ISubscriber): void {
    this.view = view
  }

  public clearView(): void {
    this.view = null
  }

  private updateView(): void {
    if (this.view) {
      this.view.update()
    }
  }

  public isLoading(): boolean {
    return this.loading
  }

  public getNews(): any {
    return this.news ?? null
  }
}

export default NewsDetailsPresenter
