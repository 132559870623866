import images from '../../../common/images'

export const advisor = [
  {
    company: '外部顧問\n清華大学 交差信息研究院 (IIIS)\n准教授',
    profile: images.o1Profile,
    title: '准教授',
    name: '唐平中',
    position: '',
    intro:
        '米国カーネギーメロン大学のコンピュータサイエンス学部で、アルゴリズム的ゲーム理論の研究に従事した後、香港科学技術大学にて博士号を取得。 スタンフォード大学コンピュータサイエンス学部、ハーバード大学コンピュータサイエンス学部、カリフォルニア大学バークレー校、マイクロソフトリサーチアジア(MSRA)にて研究員を経て、Bytedance，Huawei，DiDi，Baidu，CATLのアルゴリズム設計上級顧問を務めつつ、2012年からチューリング賞受賞者のアンドリュー・ヤオによって設立された中国清華大学の交差信息研究院(IIIS)の准教授に就任。2018年 35歳未満の世界トップ35人のイノベーター「MIT-TR35」に選出。'
  },
  {
    company: '外部顧問\nYJ Inc.',
    profile: images.o2Profile,
    title: '代表取締役',
    name: 'ヨンジュン・ヤン',
    position: '代表取締役',
    intro:
        '1984年韓国ソウル生まれ。韓国のフードデリバリー最大手企業「Woowa Bros.」の日本進出に貢献し、ドイツのフードデリバリー大手企業「Delivery Hero」にて日本事業「foodpanda」のHead of BizDevを務める。日本、フランス、アメリカ、ドイツ、韓国など異なる文化を持つ国の大手企業及びスタートアップにて事業の立ち上げから収益化までの過程を経て、クロスボーダー・コンサルティング企業YJ Inc.を創業。慶應義塾大学Executive MBA。日本語 ・ 韓国語・英語のトリリンガル。'
  }
]
