import React, { Component, type ReactElement } from 'react'
import type LeadershipDto from './LeadershipDto'
import './style.css'

interface Props {
  leader: LeadershipDto
}

class LeadershipCard extends Component<Props> {
  render(): ReactElement {
    const { company, intro, name, position, profile } = this.props.leader

    return (
      <div className='leadershipCard'>
        <div className='leadershipCard-profile'>
          <img
            alt='leadershipCard-profile-picture'
            src={profile}
            srcSet={`${profile} 1x, ${profile} 2x`}
          />
          <div className='leadershipCard-profileText'>
            {/* <p>{title}</p> */}
            <p>{name}</p>
          </div>
        </div>
        <div className='leadershipCard-title'>
          <p style={{ whiteSpace: 'pre-line' }}>{company}</p>
          <p>{position}</p>
        </div>
        <p className='leadershipCard-content'>{intro}</p>
      </div>
    )
  }
}

export default LeadershipCard
