import React, { Component, Fragment, type ReactElement } from 'react'
import parse from 'html-react-parser'
import BackToTop from '../../../common/BackToTop/BackToTop'
import Title from '../../../common/Title/Title'
import Footer from '../../../common/Footer/Footer'
import NewsMenu from '../NewsMenu/NewsMenu'
import type INewsDetailsPresenter from './Presenter/INewsDetailsPresenter'
import type ISubscriber from '../../../common/ISubscriber'
import HamburgerMenuHandler from '../../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../../common/LoadingState/LoadingState'
import type NewsDto from '../Presenter/NewsDto'
import type INewsPresentersFactory from '../PresentersFactory/INewsPresentersFactory'
import './style.scss'

interface Props {
  factory: INewsPresentersFactory
  presenter: INewsDetailsPresenter
}

class NewsDetails extends Component<Props> implements ISubscriber {
  componentDidMount(): void {
    this.props.presenter.setView(this)
  }

  componentWillUnmount(): void {
    this.props.presenter.clearView()
  }

  render(): ReactElement {
    const { factory, presenter } = this.props

    if (presenter.isLoading() || !presenter.getNews()) {
      return <LoadingState />
    }

    // @ts-expect-error ignore!
    const news: NewsDto = presenter.getNews()
    document.title = `IVA｜AI × ヒトで、世界にもっと輝きを。｜${news.title}`

    return (
      <Fragment>
        <div className='newsdetails short-page-adjustment main-content'>
          <div className='news-title'>
            <Title
              title='news'
              subtitle='イベント、プレスリリース等の'
              subtitle2='最新の情報をご案内しています'
            />
            <BackToTop />
            <NewsMenu presenter={factory.getNewsPresenter()} shouldRedirect={true} />
          </div>
          <div className='newsdetails newsdetails-content content container short-content-adjustment'>
            <div className='newsdetails-breadcrumb nav-breadcrumb'>
              <span className='breadcrumb-link'>
                <a href='/'>HOME</a>
              </span>
              <div className='arrow-black right'></div>
              <span className='breadcrumb-link'>
                <a href='/news'>ニュース</a>
              </span>
              <div className='arrow-black right'></div>
              <span id='newsdetails-breadcrumbNav'>{news.title}</span>
            </div>
            <div className='newsdetails-category'>
              {/* <p className='newsRow-tag' style={{ color: 'white', backgroundColor: `${news.date ?? '#204377'}` }}>{news.date}</p> */}
              <p className='newsdetails-date'>{news.date}</p>
            </div>
            <h2 className='h2-default'>{news.title}</h2>
            <p className='newsdetails-subtitle'>{news.subtitle}</p>

            {typeof news.content === 'string' && parse(news.content)}
            <div className='newsdetails-breadcrumb nav-breadcrumb'>
              <span className='breadcrumb-link'>
                <a href='/'>HOME</a>
              </span>
              <div className='arrow-black right'></div>
              <span className='breadcrumb-link'>
                <a href='/news'>ニュース</a>
              </span>
              <div className='arrow-black right'></div>
              <span id='newsdetails-breadcrumbNav'>{news.title}</span>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }

  update = (): void => {
    this.setState({})
  }
}

export default NewsDetails
