import React, { Component, type ReactElement } from 'react'
import type CategoryDto from '../Presenter/CategoryDto'
import './style.scss'

interface Props {
  category: CategoryDto
  id: number
  image: string
  date: string
  preview: string
  tag: string
  link: string
  type: number
}

class NewsRow extends Component<Props> {
  render(): ReactElement {
    const { category, date, id, image, preview, tag, link, type } = this.props

    return (
      <div className='newsRow'>
        <div className='newsRow-imageWrapper'>
          { type !== 2 &&
            <div className='newsRow-image'>
              <a href={link || `/newsdetails?id=${id}`} target={link ? '_blank' : ''} rel="noopener noreferrer">
                <img src={image} alt={image} />
              </a>
            </div>
          }
          { type === 2 &&
            <div className='newsRow-image'>
              <img src={image} alt={image} />
            </div>
          }
        </div>
        <div className='newsRow-content'>
          <div className='newsRow-headerWrapper'>
            <p className='newsRow-tag' style={{ backgroundColor: category.backgroundColor }}>{tag}</p>
            <p className='newsRow-date'>{date}</p>
          </div>
          {type !== 2 &&
            <a href={link || `/newsdetails?id=${id}`} target={link ? '_blank' : ''} rel="noopener noreferrer">
              <div className='newsRow-preview'>{preview}</div>
            </a>
          }
          {type === 2 &&
            <div className='newsRow-preview no-content'>{preview}</div>
          }
        </div>
      </div>
    )
  }
}

export default NewsRow
