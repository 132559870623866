import Fetcher from '../../../common/Fetcher/Fetcher'
import type INewsDetailsPresenter from '../NewsDetails/Presenter/INewsDetailsPresenter'
import NewsDetailsPresenter from '../NewsDetails/Presenter/NewsDetailsPresenter'
import type INewsPresenter from '../Presenter/INewsPresenter'
import NewsPresenter from '../Presenter/NewsPresenter'
import type INewsPresentersFactory from './INewsPresentersFactory'

class NewsPresenterFactory implements INewsPresentersFactory {
  private readonly presenter: INewsPresenter

  constructor() {
    this.presenter = new NewsPresenter(new Fetcher())
    this.initialize()
  }

  private async initialize(): Promise<void> {
    await this.presenter.initialize()
  }

  public getNewsPresenter(): INewsPresenter {
    return this.presenter
  }

  public getNewsDetailsPresenter(): INewsDetailsPresenter {
    return new NewsDetailsPresenter(new Fetcher())
  }
}

export default NewsPresenterFactory
