import React, { Component, Fragment, type ReactElement } from 'react'
import Title from '../../common/Title/Title'
import Footer from '../../common/Footer/Footer'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'

class InfoPolicy extends Component {
  render(): ReactElement {
    return (
      <Fragment>
        <div className='infoPolicy short-page-adjustment main-content'>
          <Title title='Information policy' subtitle='情報セキュリティポリシー' subtitle2='' />
          <div className='infoPolicy-content content container'>
            <div className='infoPolicy-intro'>
              <p className='mid-para'>
                当社は、すべての役員及び従業員に対し、情報の取扱に関する行動規範を定め、高い情報セキュリティレベルを確保することを重要事項と認識しております。
              </p>
              <p className='mid-para'>
                ついては、ここに情報セキュリティポリシーを定め、当社の全ての役職員は、本ポリシーに従って情報の適切な取扱い・管理・保護・維持に努めて参ります。
              </p>
            </div>
            <div>
              <h3>1．情報セキュリティへの取り組み</h3>
              <div>
                <p className='mid-para'>
                  当社は、情報セキュリティの重要性を認識し、情報セキュリティに関するリスクに対応するための管理体制を定め、関連規程を整備し、全ての役職員に周知・徹底するほか、これを定期的に見直し、継続的改善を行います。
                </p>
              </div>
            </div>
            <div>
              <h3>2．情報資産管理</h3>
              <div>
                <p className='mid-para'>
                  当社は、保有する全ての重要な情報資産（個人情報及び特定個人情報を含む）について機密性・完全性・可用性を確保するための適切な管理を行い、不正アクセス、漏洩、滅失又は毀損の防止等、安全管理に努めます。
                </p>
              </div>
            </div>
            <div>
              <h3>3．情報セキュリティ教育</h3>
              <div>
                <p className='mid-para'>
                  当社は、情報セキュリティの必要性及び具体的な遵守事項を全ての役職員に周知・徹底するとともに、当社の情報管理体制を維持向上させるために、全ての役職員に対し情報セキュリティに関する教育を定期的に実施いたします。
                </p>
              </div>
            </div>
            <div>
              <h3>4．情報セキュリティ事件・事故への予防と対応</h3>
              <div>
                <p className='mid-para'>
                  当社は、情報セキュリティに関する事件・事故の発生予防に努めるとともに、万一発生した場合はその原因を迅速に究明し、再発防止策を含む適切な対策を速やかに講じます。
                </p>
              </div>
            </div>
            <div>
              <h3>5．法令等の遵守</h3>
              <div>
                <p className='mid-para'>
                  当社は、情報セキュリティに関連する法令、規制及びその他の社会的規範を遵守します。
                </p>
                <p className='mid-para content-date'>平成31年4月20日 策定</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(false)} />
      </Fragment>
    )
  }
}

export default InfoPolicy
