import React, { Component, Fragment, type ReactElement } from 'react'
import Footer from '../../common/Footer/Footer'
import WhiteTitle from '../../common/WhiteTitle/WhiteTitle'
import images from '../../common/images'
import HeaderWrapper from '../../common/Header/HeaderWrapper/HeaderWrapper'
import BackToTop from '../../common/BackToTop/BackToTop'
import HamburgerMenuHandler from '../../common/Header/HeaderHandler/HamburgerMenuHandler'
import SideBarMenuMobile from '../../common/SideBarMenuMobile/SideBarMenuMobile'
import LoadingState from '../../common/LoadingState/LoadingState'
import NavigationButton from '../../common/NavigationButton/NavigationButton'
import './style.scss'

class message extends Component {
  componentDidMount(): void {
    document.title = 'IVA｜AI × ヒトで、世界にもっと輝きを。｜トップメッセージ'
    document.addEventListener('wheel', this.handleScroll)
    document.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.handleScroll)
    document.removeEventListener('scroll', this.handleScroll)
  }

  render(): ReactElement {
    return (
      <Fragment>
        <div className='message main-content'>
          <LoadingState />
          <HeaderWrapper shouldShowBlackLogo={true} />
          <BackToTop />
          <WhiteTitle
            pageTitle={images.messageLogo}
            title=''
            title2=''
            titleWidth='220px'
            subtitle=''
            subtitle2=''
          />

          <img className='banner' src={images.messageBanner} alt='' />

          <div className='message-content content-padding container section-animation'>
            <h1 className='main-title'>ガムシャラに、粛々と。</h1>
            <p className='mb-5'>
              「55兆円市場をハックし、経済のポテンシャルを解放する」。2019年に創業したIVAはこのビジョンの実現を目指し、必死に走り続けてきました。スタートアップらしく、誰も解決できなかった未知であり巨大な課題に果敢にチャレンジをし続けた結果、現在では個人のユーザにはじまり、中小企業から大企業まで幅広くご利用いただけるサービスへと成長を遂げました。
            </p>
            <p className='mb-5'>
              IVAの壮大なストーリーはまだ始まったばかりです。既にカバーしている領域においてもチャレンジできることが無限にありますし、未踏の領域も数多く存在しています。ビジョンの実現のためには、地球規模でアーキテクチャを構築することが不可欠で、スケーラビリティを考えてもさらに社会にインパクトを与えるようなチャレンジが必要となります。
            </p>
            <p className='mb-5'>
              IVAの生み出す価値で、世界にもっと輝きをもたらす。それが私たちの使命であり、目指すべき姿だと考えています。そのために、今後も「AI
              × ヒト（AI-POWERED and HUMAN
              DRIVEN）」を軸に、最先端の技術と最大限の泥臭さをもって、新しい仕組みやムーブメントを生み出していきます。
            </p>
            <p className='mb-5'>
              不確実性の中でガムシャラに進む道を切り開き、粛々と課題やそのソリューションを自分自身で模索できる方。またIVAのミッション、ビジョン、バリューに共感し地球規模のインパクトを創り出す仕事に挑戦したい方。そんなあなたがIVAチームの一員になる日を待っています。
            </p>

            <div className='sign-zone'>
              <div></div>
              <div className='sign-content'>
                <div className='name'>
                  <div>IVA株式会社</div>
                  <div>代表取締役CEO</div>
                  <div style={{ fontWeight: 'bold' }}>相原嘉夫</div>
                </div>
                <div>
                  <img src={images.messageSign} alt='' />
                </div>
              </div>
            </div>

            <div className='home-navButtonWrapper'>
              <NavigationButton height='50' label='会社概要を見る' path='company' width='285' />
            </div>
          </div>

          <Footer />
        </div>
        <SideBarMenuMobile handler={new HamburgerMenuHandler(true)} />
      </Fragment>
    )
  }

  private readonly handleScroll = (): void => {
    this.handleAnimationElements()
    this.handleSectionAnimation()
  }

  private readonly handleAnimationElements = (): void => {
    const animationElements = document.querySelectorAll('.title-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }

  private readonly handleSectionAnimation = (): void => {
    const animationElements = document.querySelectorAll('.section-animation')
    const windowHeight = window.innerHeight

    for (let i = 0; i < animationElements.length; i++) {
      const elementTop = animationElements[i].getBoundingClientRect().top
      const elementVisible = 10

      if (elementTop < windowHeight - elementVisible) {
        animationElements[i].classList.add('active')
      } else {
        animationElements[i].classList.remove('active')
      }
    }
  }
}

export default message
