class DateHelper {
  public static formatDate(dateString: string): string {
    if (!dateString) {
      return ''
    }
    const date = new Date(dateString.replace(/-/g, '/'))

    return `${date.getFullYear()}年${(date.getMonth() + 1).toString()}月${date.getDate().toString()}日`
  }

  public static getYear(dateString: string): string {
    if (!dateString) {
      return ''
    }
    const date = new Date(dateString.replace(/-/g, '/'))

    return `${date.getFullYear()}`
  }
}

export default DateHelper
