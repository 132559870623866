import type IFetcher from './IFetcher'

class Fetcher implements IFetcher {
  public async fetch(params: {
    body?: any
    method: string
    url: string
  }): Promise<any> {
    const { body, method, url } = params

    const result = await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json'
      },
      body: method === 'GET' ? undefined : JSON.stringify(body)
    })

    if (result) {
      return await result.json()
    }

    return null
  }
}

export default Fetcher
