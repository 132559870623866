import React, { Component, Fragment, type ReactElement } from 'react'
import images from '../images'
import type IHeaderHandler from '../Header/HeaderHandler/IHeaderHandler'
import './style.css'

interface Props {
  handler: IHeaderHandler
}

class SideBarMenuMobile extends Component<Props> {
  componentDidMount(): void {
    document.addEventListener('wheel', this.props.handler.handleNavBarOnScroll)
    document.addEventListener('scroll', this.props.handler.handleNavBarOnScroll)
  }

  componentWillUnmount(): void {
    document.removeEventListener('wheel', this.props.handler.handleNavBarOnScroll)
    document.removeEventListener('scroll', this.props.handler.handleNavBarOnScroll)
  }

  render(): ReactElement {
    const { handler } = this.props

    return (
      <Fragment>
        <div id='main-content-fill'></div>
        <div id='side-bar-mobile-toggle'>
          <a
            className={`toggle-icon ${handler.shouldShowBlackLogo ? 'more-black' : 'more-white'}`}
            onClick={this.handleSideBarMobile}
          ></a>
        </div>

        <div id='side-bar-mobile'>
          <a style={{ width: 'fit-content' }} href='/' referrerPolicy='no-referrer'>
            <img
              alt='logo'
              className='header-logo'
              style={{ height: '20px', marginRight: 'auto' }}
              src={images.logo}
            />
          </a>
          <div className='side-bar-mobile-menu'>
            <a className='side-bar-mobile-menu-link' href='/message' referrerPolicy='no-referrer'>
              <span>MESSAGE</span>
              <span>トップメッセージ</span>
            </a>
            <a className='side-bar-mobile-menu-link' href='/company' referrerPolicy='no-referrer'>
              <span>COMPANY</span>
              <span>会社概要</span>
            </a>
            <div
              className='side-bar-mobile-menu-link'
              onClick={() => this.openSideBarMobileSubmenu('submenuNews')}
            >
              <span>NEWS</span>
              <span>ニュース</span>
            </div>
            <div
              className='side-bar-mobile-menu-link'
              onClick={() => this.openSideBarMobileSubmenu('submenuCareers')}
            >
              <span>CAREERS</span>
              <span>採用情報</span>
            </div>
            <a className='side-bar-mobile-menu-link' href='/mediakit' referrerPolicy='no-referrer'>
              <span>MEDIA KIT</span>
              <span>メディアキット</span>
            </a>
            <a className='side-bar-mobile-menu-link' href='/contact' referrerPolicy='no-referrer'>
              <span>CONTACT</span>
              <span>お問い合わせ</span>
            </a>
            <div className='side-bar-mobile-menu-fakeBustersLink'>
              <a
                className='fakeBustersLink-link'
                href='https://www.fakebusters-iva.com/ja'
                target='_blank'
                referrerPolicy='no-referrer'
                rel='noreferrer'
              >
                <img alt='fake-busters-logo-link' src={images.fakebustersLink} />
              </a>
            </div>
          </div>

          {/* News Submenu */}
          <div className='side-bar-mobile-submenu' id='submenuNews'>
            <div
              className='submenu-header'
              onClick={() => this.closeSideBarMobileSubmenu('submenuNews')}
            >
              <img className='submenu-back-icon' alt='arrow-left' src={images.arrowRight} />
              <div className='submenu-title'>
                <span>NEWS</span>
                <span>ニュース</span>
              </div>
            </div>
            <a className='submenu-link' href='/news' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>ニューストップ</span>
            </a>
            <a className='submenu-link' href='/news?year=2024' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>2024年</span>
            </a>
            <a className='submenu-link' href='/news?year=2023' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>2023年</span>
            </a>
            <a className='submenu-link' href='/news?year=2022' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>2022年</span>
            </a>
            <a className='submenu-link' href='/news?year=2021' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>2021年</span>
            </a>
            <a className='submenu-link' href='/news?year=2020' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>2020年</span>
            </a>
            <a className='submenu-link' href='/news?year=2019' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>2019年</span>
            </a>
          </div>

          {/* Careers Submenu */}
          <div className='side-bar-mobile-submenu' id='submenuCareers'>
            <div
              className='submenu-header'
              onClick={() => this.closeSideBarMobileSubmenu('submenuCareers')}
            >
              <img className='submenu-back-icon' alt='arrow-left' src={images.arrowRight} />
              <div className='submenu-title'>
                <span>CAREERS</span>
                <span>採用情報</span>
              </div>
            </div>
            <a className='submenu-link' href='/careers' referrerPolicy='no-referrer'>
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>採用情報トップ</span>
            </a>
            <a
              className='submenu-link'
              href='https://herp.careers/v1/ivacorp'
              target='_blank'
              rel='noreferrer'
            >
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>求人職種一覧</span>
            </a>
            <a
              className='submenu-link'
              href='https://www.wantedly.com/companies/company_6154268'
              target='_blank'
              rel='noreferrer'
            >
              <img className='submenu-navigation-icon' alt='arrow-right' src={images.arrowRight} />
              <span>Wantedly 採用ページ</span>
            </a>
          </div>
        </div>
      </Fragment>
    )
  }

  private readonly handleSideBarMobile = (): void => {
    const mainContentBlock = document.querySelector('.main-content') as HTMLElement
    const mainContentBlockFill = document.getElementById('main-content-fill') as HTMLElement
    const sideBarMobile = document.getElementById('side-bar-mobile') as HTMLElement
    const sideBarMobileMenu = document.querySelector('.side-bar-mobile-menu') as HTMLElement
    const sideBarMobileSubmenu = document.querySelectorAll(
      '.side-bar-mobile-submenu'
    ) as NodeListOf<HTMLElement>
    const toggleIcon = document.querySelector('.toggle-icon') as HTMLElement

    if (mainContentBlock && sideBarMobile) {
      if (sideBarMobile.classList.contains('show')) {
        sideBarMobile.classList.remove('show')
        mainContentBlockFill.classList.remove('show')
        mainContentBlock.classList.remove('go-down')
        toggleIcon.classList.remove('cancel')
      } else {
        // 開啟時 reset
        sideBarMobileMenu.scrollTo({ top: 0 })
        sideBarMobileSubmenu.forEach((element) => {
          element.classList.remove('show')
        })

        sideBarMobile.classList.add('show')
        mainContentBlockFill.classList.add('show')
        mainContentBlock.classList.add('go-down')
        toggleIcon.classList.add('cancel')
      }
    }
  }

  private readonly openSideBarMobileSubmenu = (id: string): void => {
    const element = document.getElementById(id) as HTMLElement

    if (element) {
      element.classList.add('show')
    }
  }

  private readonly closeSideBarMobileSubmenu = (id: string): void => {
    const element = document.getElementById(id) as HTMLElement

    if (element) {
      element.classList.remove('show')
    }
  }
}

export default SideBarMenuMobile
