import React, { useEffect, useState } from 'react'
import images from '../../common/images'
import files from '../../common/files'
import './style.scss'

export default function MediakitFb(): JSX.Element {
  const brandGuidelineImageCounts = 7
  const [brandGuidelineImage, setBrandGuidelineImage] = useState<number>(0)
  const [brandGuidelineImageTimeoutId, setBrandGuidelineImageTimeId] = useState<NodeJS.Timeout>()

  const selectBrandGuidelineImage = (piece: number): void => {
    if (piece !== brandGuidelineImage) {
      clearTimeout(brandGuidelineImageTimeoutId)
      setBrandGuidelineImage(piece)
    }
  }

  const nextBrandGuidelineImage = (): void => {
    clearTimeout(brandGuidelineImageTimeoutId)
    if (brandGuidelineImage === brandGuidelineImageCounts - 1) {
      setBrandGuidelineImage(0)
    } else {
      setBrandGuidelineImage((prevValue) => prevValue + 1)
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (brandGuidelineImage === brandGuidelineImageCounts - 1) {
        setBrandGuidelineImage(0)
      } else {
        setBrandGuidelineImage((prevValue) => prevValue + 1)
      }
    }, 5000)
    setBrandGuidelineImageTimeId(timeoutId)

    return () => clearTimeout(timeoutId)
  }, [brandGuidelineImage])

  return (
    <>
      <div className='mediakit-logos-block' style={{ marginTop: '100px' }}>
        <div className='mediakit-logos-title' id='fakebustrs-logos'>
          <b>FAKEBUSTERS LOGOS</b>
          <div className='dividing-line'></div>
        </div>
      </div>
      <div className='mediakit-logos-block'>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>For WEB</span>
            <span className='subtitle-with-bg'>Web向け</span>
          </span>
        </div>
        <div className='mediakit-logos-for-web'>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image'
                href={images.logoFbTrademarkBlackPng}
                target='_blank'
                download='logo-fb-trademark-black.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbTrademarkBlackSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Trademark</b>
                <b>Black HEX #000000</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbTrademarkBlackPng}
                  target='_blank'
                  download='logo-fb-trademark-black.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbTrademarkBlackSvg}
                  target='_blank'
                  download='logo-fb-trademark-black.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image'
                href={images.logoFbHorizontalBlackPng}
                target='_blank'
                download='logo-fb-horizontal-black.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbHorizontalBlackSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Horizontal Type</b>
                <b>Black HEX #000000</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbHorizontalBlackPng}
                  target='_blank'
                  download='logo-fb-horizontal-black.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbHorizontalBlackSvg}
                  target='_blank'
                  download='logo-fb-horizontal-black.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image'
                href={images.logoFbVerticalBlackPng}
                target='_blank'
                download='logo-fb-vertical-black.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbVerticalBlackSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Vertical Type</b>
                <b>Black HEX #000000</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbVerticalBlackPng}
                  target='_blank'
                  download='logo-fb-vertical-black.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbVerticalBlackSvg}
                  target='_blank'
                  download='logo-fb-vertical-black.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
          </div>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image'
                style={{ padding: '65px' }}
                href={images.logoFbAppolBlackPng}
                target='_blank'
                download='logo-fb-appol-black.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbAppolBlackSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS App Outline</b>
                <b>Black HEX #000000</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbAppolBlackPng}
                  target='_blank'
                  download='logo-fb-appol-black.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbAppolBlackSvg}
                  target='_blank'
                  download='logo-fb-appol-black.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image'
                style={{ padding: '65px' }}
                href={images.logoFbAppBlackPng}
                target='_blank'
                download='logo-fb-app-black.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbAppBlackSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS App</b>
                <b>Black HEX #000000</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbAppBlackPng}
                  target='_blank'
                  download='logo-fb-app-black.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbAppBlackSvg}
                  target='_blank'
                  download='logo-fb-app-black.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
          </div>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image black'
                href={images.logoFbTrademarkWhitePng}
                target='_blank'
                download='logo-fb-trademark-white.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbTrademarkWhiteSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Trademark</b>
                <b>White HEX #FFFFFF</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbTrademarkWhitePng}
                  target='_blank'
                  download='logo-fb-trademark-white.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbTrademarkWhiteSvg}
                  target='_blank'
                  download='logo-fb-trademark-white.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image black'
                href={images.logoFbHorizontalWhitePng}
                target='_blank'
                download='logo-fb-horizontal-white.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbHorizontalWhiteSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Horizontal Type</b>
                <b>White HEX #FFFFFF</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbHorizontalWhitePng}
                  target='_blank'
                  download='logo-fb-horizontal-white.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbHorizontalWhiteSvg}
                  target='_blank'
                  download='logo-fb-horizontal-white.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image black'
                href={images.logoFbVerticalWhitePng}
                target='_blank'
                download='logo-fb-vertical-white.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbVerticalWhiteSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Vertical Type</b>
                <b>White HEX #FFFFFF</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbVerticalWhitePng}
                  target='_blank'
                  download='logo-fb-vertical-white.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbVerticalWhiteSvg}
                  target='_blank'
                  download='logo-fb-vertical-white.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
          </div>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image black'
                style={{ padding: '65px' }}
                href={images.logoFbAppolWhitePng}
                target='_blank'
                download='logo-fb-appol-white.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbAppolWhiteSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS App Outline</b>
                <b>White HEX #FFFFFF</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbAppolWhitePng}
                  target='_blank'
                  download='logo-fb-appol-white.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbAppolWhiteSvg}
                  target='_blank'
                  download='logo-fb-appol-white.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image black'
                style={{ padding: '65px' }}
                href={images.logoFbAppWhitePng}
                target='_blank'
                download='logo-fb-app-white.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbAppWhiteSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS App</b>
                <b>White HEX #FFFFFF</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbAppWhitePng}
                  target='_blank'
                  download='logo-fb-app-white.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbAppWhiteSvg}
                  target='_blank'
                  download='logo-fb-app-white.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
          </div>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image black'
                href={images.logoFbTrademarkColorPng}
                target='_blank'
                download='logo-fb-trademark-color.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbTrademarkColorSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Trademark</b>
                <b>Color HEX #44B582</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbTrademarkColorPng}
                  target='_blank'
                  download='logo-fb-trademark-color.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbTrademarkColorSvg}
                  target='_blank'
                  download='logo-fb-trademark-color.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image black'
                href={images.logoFbHorizontalColorPng}
                target='_blank'
                download='logo-fb-horizontal-color.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbHorizontalColorSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Horizontal Type</b>
                <b>Color HEX #44B582</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbHorizontalColorPng}
                  target='_blank'
                  download='logo-fb-horizontal-color.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbHorizontalColorSvg}
                  target='_blank'
                  download='logo-fb-horizontal-color.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image black'
                href={images.logoFbVerticalColorPng}
                target='_blank'
                download='logo-fb-vertical-color.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbVerticalColorSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS Vertical Type</b>
                <b>Color HEX #44B582</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbVerticalColorPng}
                  target='_blank'
                  download='logo-fb-vertical-color.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbVerticalColorSvg}
                  target='_blank'
                  download='logo-fb-vertical-color.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
          </div>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image black'
                style={{ padding: '65px' }}
                href={images.logoFbAppolColorPng}
                target='_blank'
                download='logo-fb-appol-color.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbAppolColorSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS App Outline</b>
                <b>Color HEX #44B582</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbAppolColorPng}
                  target='_blank'
                  download='logo-fb-appol-color.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbAppolColorSvg}
                  target='_blank'
                  download='logo-fb-appol-color.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image black'
                style={{ padding: '65px' }}
                href={images.logoFbAppColorPng}
                target='_blank'
                download='logo-fb-app-color.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoFbAppColorSvg} />
              </a>
              <div className='logo-title'>
                <b>FAKEBUSETERS App</b>
                <b>Color HEX #44B582</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoFbAppColorPng}
                  target='_blank'
                  download='logo-fb-app-color.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoFbAppColorSvg}
                  target='_blank'
                  download='logo-fb-app-color.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Oct 1, 2023</span>
                <span>Created on Oct 1, 2023</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mediakit-logos-block'>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>For PRINT</span>
            <span className='subtitle-with-bg'>印刷向け</span>
          </span>
        </div>
        <div className='mediakit-logos-for-print'>
          <div className='logo-image for-print' style={{ padding: '30px 60px 30px 60px' }}>
            <a
              href={images.logofakebustersForPrint}
              target='_blank'
              download='fakebusters-logo-kit2310a.ai'
              rel='noreferrer'
            >
              <img
                alt='iva-logo'
                src={images.logofakebustersForPrintSvg}
                width='100%'
                height='100%'
              />
            </a>
          </div>
          <div className='logo-info-area'>
            <b>FAKEBUSTERS Logo Kit (CMYK)</b>
            <div className='mediakit-logo-download'>
              <a
                href={images.logofakebustersForPrint}
                target='_blank'
                download='fakebusters-logo-kit2310a.ai'
                rel='noreferrer'
              >
                AI (Adobe Illustrator)
              </a>
            </div>
            <div className='logo-info'>
              <span>Last updated on Oct 1, 2023</span>
              <span>Created on Oct 1, 2023</span>
            </div>
          </div>
        </div>
      </div>
      <div className='mediakit-logos-block' style={{ marginBottom: '100px' }}>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>BRAND GUIDELINE</span>
            <span className='subtitle-with-bg'>ブランドガイドライン</span>
          </span>
        </div>
        <div className='mediakit-logos-for-print'>
          <div
            className='logo-brand-guideline'
            style={{
              backgroundImage: `url(${images.logofakebustersGuideineArray[brandGuidelineImage]})`
            }}
            onClick={() => nextBrandGuidelineImage()}
          >
            <img
              className='logo-brand-guideline-image'
              src={images.logofakebustersGuideineArray[brandGuidelineImage]}
              alt='fb-logo'
            />
          </div>
          <div className='logo-brand-guideline-select-block'>
            {Array.from({ length: brandGuidelineImageCounts }, (_, index) => (
              <span
                className={`logo-brand-guideline-select ${
                  brandGuidelineImage === index ? 'selected' : ''
                }`}
                key={index}
                onClick={() => selectBrandGuidelineImage(index)}
              ></span>
            ))}
          </div>
          {Array.from({ length: brandGuidelineImageCounts }, (_, index) => (
            <img
              src={images.logofakebustersGuideineArray[index]}
              alt='fb-logo'
              key={index}
              style={{ display: 'none' }}
            />
          ))}
          <div className='logo-info-area'>
            <b>FAKEBUSTERS Bland Guideline</b>
            <div className='mediakit-logo-download'>
              <a
                href={files.fakebustersBrandGuideline}
                target='_blank'
                download='fakebusters-brand-guideline2310a.pdf'
                rel='noreferrer'
              >
                PDF
              </a>
            </div>
            <div className='logo-info'>
              <span>Last updated on Oct 1, 2023</span>
              <span>Created on Oct 1, 2023</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
