import React, { Component, type ReactElement } from 'react'
import './style.css'

interface Props {
  pageTitle: string
  subtitle: string
  subtitle2: string
  title: string
  title2: string
  titleWidth: string
}

class WhiteTitle extends Component<Props> {
  render(): ReactElement {
    const { pageTitle, subtitle, subtitle2, title, title2, titleWidth } = this.props

    return (
      <div className='whiteTitle'>
        <div className='whiteTitle-content'>
          <img alt='page-title' src={pageTitle} style={{ width: `${titleWidth}` }} />
          <h1>
            <div>{title.toUpperCase()}</div>
            <div>{title2.toUpperCase()}</div>
          </h1>
          <p className='whiteTitle-subtitle'>
            <div>{subtitle}</div>
            <div>{subtitle2}</div>
          </p>
        </div>
      </div>
    )
  }
}

export default WhiteTitle
