import React, { useEffect, useState } from 'react'
import images from '../../common/images'
import files from '../../common/files'
import './style.scss'

export default function MediakitIva(): JSX.Element {
  const brandGuidelineImageCounts = 7
  const [brandGuidelineImage, setBrandGuidelineImage] = useState<number>(0)
  const [brandGuidelineImageTimeoutId, setBrandGuidelineImageTimeId] = useState<NodeJS.Timeout>()

  const selectBrandGuidelineImage = (piece: number): void => {
    if (piece !== brandGuidelineImage) {
      clearTimeout(brandGuidelineImageTimeoutId)
      setBrandGuidelineImage(piece)
    }
  }

  const nextBrandGuidelineImage = (): void => {
    clearTimeout(brandGuidelineImageTimeoutId)
    if (brandGuidelineImage === brandGuidelineImageCounts - 1) {
      setBrandGuidelineImage(0)
    } else {
      setBrandGuidelineImage((prevValue) => prevValue + 1)
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (brandGuidelineImage === brandGuidelineImageCounts - 1) {
        setBrandGuidelineImage(0)
      } else {
        setBrandGuidelineImage((prevValue) => prevValue + 1)
      }
    }, 5000)
    setBrandGuidelineImageTimeId(timeoutId)

    return () => clearTimeout(timeoutId)
  }, [brandGuidelineImage])

  return (
    <>
      <div className='mediakit-logos-block'>
        <div className='mediakit-logos-title' id='iva-logos'>
          <b>IVA LOGOS</b>
          <div className='dividing-line'></div>
        </div>
      </div>
      <div className='mediakit-logos-block'>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>For WEB</span>
            <span className='subtitle-with-bg'>Web向け</span>
          </span>
        </div>
        <div className='mediakit-logos-for-web'>
          <div className='logo-row'>
            <div>
              <a
                className='logo-image'
                href={images.logoIvaColorPng}
                target='_blank'
                download='logo-iva-color.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoIvaColorSvg} />
              </a>
              <div className='logo-title'>
                <b>IVA Color HEX #003F84</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoIvaColorPng}
                  target='_blank'
                  download='logo-iva-color.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoIvaColorSvg}
                  target='_blank'
                  download='logo-iva-color.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Jan 22, 2024</span>
                <span>Created on Jan 22, 2024</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image'
                href={images.logoIvaBlackPng}
                target='_blank'
                download='logo-iva-black.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoIvaBlackSvg} />
              </a>
              <div className='logo-title'>
                <b>IVA Black HEX #000000</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoIvaBlackPng}
                  target='_blank'
                  download='logo-iva-black.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoIvaBlackSvg}
                  target='_blank'
                  download='logo-iva-black.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Jan 22, 2024</span>
                <span>Created on Jan 22, 2024</span>
              </div>
            </div>
            <div>
              <a
                className='logo-image blue'
                href={images.logoIvaWhitePng}
                target='_blank'
                download='logo-iva-white.png'
                rel='noreferrer'
              >
                <img alt='iva-logo' src={images.logoIvaWhiteSvg} />
              </a>
              <div className='logo-title'>
                <b>IVA White HEX #FFFFFF</b>
              </div>
              <div className='mediakit-logo-download'>
                <a
                  href={images.logoIvaWhitePng}
                  target='_blank'
                  download='logo-iva-white.png'
                  rel='noreferrer'
                >
                  PNG
                </a>
                <a
                  href={images.logoIvaWhiteSvg}
                  target='_blank'
                  download='logo-iva-white.svg'
                  rel='noreferrer'
                >
                  SVG
                </a>
              </div>
              <div className='logo-info'>
                <span>Last updated on Jan 22, 2024</span>
                <span>Created on Jan 22, 2024</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mediakit-logos-block'>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>For PRINT</span>
            <span className='subtitle-with-bg'>印刷向け</span>
          </span>
        </div>
        <div className='mediakit-logos-for-print'>
          <div className='logo-image for-print'>
            <a
              href={images.logoIvaForPrint}
              target='_blank'
              download='iva-logo-kit2401a.ai'
              rel='noreferrer'
            >
              <img alt='iva-logo' src={images.logoIvaForPrintSvg} width='100%' height='100%' />
            </a>
          </div>
          <div className='logo-info-area'>
            <b>IVA Logo Kit (CMYK)</b>
            <div className='mediakit-logo-download'>
              <a
                href={images.logoIvaForPrint}
                target='_blank'
                download='iva-logo-kit2401a.ai'
                rel='noreferrer'
              >
                AI (Adobe Illustrator)
              </a>
            </div>
            <div className='logo-info'>
              <span>Last updated on Jan 22, 2024</span>
              <span>Created on Jan 22, 2024</span>
            </div>
          </div>
        </div>
      </div>
      <div className='mediakit-logos-block'>
        <div className='title-wrapper'>
          <span className='title-with-bg-wrapper'>
            <i className='title-animation'></i>
            <span className='title-with-bg'>BRAND GUIDELINE</span>
            <span className='subtitle-with-bg'>ブランドガイドライン</span>
          </span>
        </div>
        <div className='mediakit-logos-for-print'>
          <div
            className='logo-brand-guideline'
            style={{ backgroundImage: `url(${images.logoIvaGuideineArray[brandGuidelineImage]})` }}
            onClick={() => nextBrandGuidelineImage()}
          >
            <img
              className='logo-brand-guideline-image'
              src={images.logoIvaGuideineArray[brandGuidelineImage]}
              alt='iva-logo'
            />
          </div>
          <div className='logo-brand-guideline-select-block'>
            {Array.from({ length: brandGuidelineImageCounts }, (_, index) => (
              <span
                className={`logo-brand-guideline-select ${
                  brandGuidelineImage === index ? 'selected' : ''
                }`}
                key={index}
                onClick={() => selectBrandGuidelineImage(index)}
              ></span>
            ))}
          </div>
          {Array.from({ length: brandGuidelineImageCounts }, (_, index) => (
            <img
              src={images.logoIvaGuideineArray[index]}
              alt='iva-logo'
              key={index}
              style={{ display: 'none' }}
            />
          ))}
          <div className='logo-info-area'>
            <b>IVA Bland Guideline</b>
            <div className='mediakit-logo-download'>
              <a
                href={files.ivaBrandGuideline}
                target='_blank'
                download='iva-brand-guideline2401a.pdf'
                rel='noreferrer'
              >
                PDF
              </a>
            </div>
            <div className='logo-info'>
              <span>Last updated on Jan 22, 2024</span>
              <span>Created on Jan 22, 2024</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
