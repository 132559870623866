import React, { Component, Fragment, type ReactElement } from 'react'
import type ValueDto from './ValueDto'
import { fasterAnimationDelayMilliseconds } from '../../../common/config'
import { MOBILE_BREAKPOINT } from '../../../common/Header/HeaderHandler/HeaderHandler'
import './style.scss'
import images from '../../../../src/common/images'

interface Props {
  value: ValueDto
}

class ValuesCard extends Component<Props> {
  componentDidMount = (): void => {
    if (window.innerWidth >= MOBILE_BREAKPOINT) {
      this.setAnimationCounter(0)
    } else {
      this.expandFirstMobileCardOnMount()
    }
  }

  componentWillUnmount(): void {
    this.terminateAnimation()
  }

  render(): ReactElement {
    const { content, englishTitle, index, title } = this.props.value

    return (
      <Fragment>
        <div
          className='valuesCard valuesCard-lg'
          id={`collapsedContent-${index}`}
          onClick={(e) => {
            this.handleClick(e)
          }}
        >
          {/* style={{ backgroundImage: `url(${images.valueCardBg}_${index})` }} */}
          {index === '01' &&
            <img src={images.valueCardBg1} alt="" />
          }
          {index === '02' &&
            <img src={images.valueCardBg2} alt="" />
          }
          {index === '03' &&
            <img src={images.valueCardBg3} alt="" />
          }
          {index === '04' &&
            <img src={images.valueCardBg4} alt="" />
          }
          <div className='valuesCard-content'>
            <div className='valuesCard-title'>
              <h4>{title}</h4>
              <div className='valuesCard-index'>{index}</div>
            </div>
            <p className='valuesCard-englishTitle'>{englishTitle}</p>
            <span className='valuesCard-divider'></span>
            <p className='values-details'>{content}</p>
          </div>
        </div>
      </Fragment>
    )
  }

  private readonly handleClick = (event: React.MouseEvent): void => {
    const valuesCards = Array.from(document.querySelectorAll('.valuesCard-lg')) as HTMLElement[]
    valuesCards.forEach((card) => (card.className = 'valuesCard valuesCard-lg'))
    const selectedValuesCard = (event.target as HTMLElement).closest('.valuesCard-lg')

    if (selectedValuesCard) {
      selectedValuesCard.className = 'valuesCard valuesCard-lg'
    }
  }

  private readonly expandFirstMobileCardOnMount = (): void => {
    if (this.props.value.index === '01') {
      this.hideCollapsedTitle()
      this.expandMobileCard()
    }
  }

  private readonly expandCard = (): void => {
    const titles = Array.from(document.querySelectorAll('.valuesCard-collapsed')) as HTMLElement[]
    titles.forEach((title) => {
      title.style.height = '72px'
    })

    this.hideMobileCards()
    this.hideCollapsedTitle()
    this.expandMobileCard()
  }

  private readonly hideMobileCards = (): void => {
    const cards = Array.from(document.querySelectorAll('.valuesCard-mobileCard')) as HTMLElement[]
    cards.forEach((card) => {
      card.style.display = 'none'
    })

    const cardContent = Array.from(document.querySelectorAll('.values-details')) as HTMLElement[]
    cardContent.forEach((content) => {
      content.style.opacity = '0'
    })
  }

  private readonly hideCollapsedTitle = (): void => {
    const { index } = this.props.value

    const titleTexts = Array.from(document.querySelectorAll('.valuesCard-collapsedContent')) as HTMLElement[]
    titleTexts.forEach((text) => {
      text.style.display = 'grid'
    })

    const titleText = document.querySelector(`#collapsedTitle-${index} > .valuesCard-collapsedContent`) as HTMLElement
    if (titleText) {
      titleText.style.display = 'none'
    }
  }

  private readonly expandMobileCard = (): void => {
    const { index } = this.props.value
    const title = document.querySelector(`#collapsedTitle-${index}`) as HTMLElement
    if (title) {
      title.style.height = '318px'
    }

    const mobileCard = document.querySelector(`#collapsedTitle-${index} > .valuesCard-mobileCard`) as HTMLElement
    if (mobileCard) {
      mobileCard.style.display = 'flex'
      mobileCard.style.visibility = 'visible'

      const cardContent = document.querySelector(`#collapsedTitle-${index} > .valuesCard-mobileCard > .valuesCard-bg > .valuesCard-content > .values-details`) as HTMLElement
      if (cardContent) {
        cardContent.style.opacity = '1'
      }
    }
  }

  private readonly setAnimationCounter = (index: number): void => {
    if (index === 100) {
      return
    }

    const cards = Array.from(document.querySelectorAll('.valuesCard')) as HTMLElement[]
    const prevCard = cards[index === 0 ? cards.length - 1 : index - 1]
    const currentCard = cards[index]

    if (cards.length) {
      if (currentCard) {
        currentCard.className = 'valuesCard valuesCard-lg valuesCard-onFocus'
      }
      if (prevCard) {
        prevCard.className = 'valuesCard valuesCard-lg'
      }

      setTimeout(() => {
        this.setAnimationCounter(index + 1 === cards.length ? 0 : index + 1)
      }, fasterAnimationDelayMilliseconds)
    }
  }

  private readonly terminateAnimation = (): void => {
    this.setAnimationCounter(100)
  }
}

export default ValuesCard
